import logo from '../images/logo/MagnumLogo.png';
import menu from '../images/icons/menu.svg';
import close from '../images/icons/close.svg';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

// import Header from '../components/Header';
// import ban1 from '../images/banner/banner1.png';
// import img1 from '../images/img/img1.png';
// import comp2Img from '../images/img/comp2.png';

import Footer from '../components/Footer';


import im12 from '../images/img/12.png';
import im13 from '../images/img/13.png';
import im14 from '../images/img/14.png';

function BPO() {

    const navigate = useNavigate()
  const [menuBtn, setMenuBtn] = useState(false);
  const [menu1, setMenu1] = useState(false);
  const [menu2, setMenu2] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (

    <>
    
    <div className='head_main desktop'>
          <div className='head_logo'><img src={logo} alt="logo" /></div>
          <div className='head_main_links'>
              <div onClick={() => navigate("/")} className='head_main_links_div '>Home</div>
              <div className='head_main_links_div' onMouseOver={() => setMenu1(true)} onMouseLeave={() => setMenu1(false)}>About Us
              {menu1 && (
                  <div className='head_main_links_cont' onMouseOver={() => setMenu1(true)} onMouseLeave={() => setMenu1(false)}>
                      <div onClick={() => navigate("/CompanyProfile")} className='head_main_links_cont_link'>Company Profile</div>
                      <div onClick={() => navigate("/JourneyAndGrowth")} className='head_main_links_cont_link'>Magnum Journey & Growth</div>
                      <div onClick={() => navigate("/Teams")} className='head_main_links_cont_link'>Magnum Team</div>
                  </div>
              )}
              </div>
              <div className='head_main_links_div' onMouseOver={() => setMenu2(true)} onMouseLeave={() => setMenu2(false)}>Services
              {menu2 && (
                <div className='head_main_links_cont' onMouseOver={() => setMenu2(true)} onMouseLeave={() => setMenu2(false)}>
                  <div onClick={() => navigate("/BPO")} className='head_main_links_cont_link activeLinkHead'>BPO</div>
                  <div onClick={() => navigate("/KPO")} className='head_main_links_cont_link'>KPO</div>
                  <div onClick={() => navigate("/OperationAndMain")} className='head_main_links_cont_link'>Operation & Maintanance</div>
                  <div onClick={() => navigate("/Utility")} className='head_main_links_cont_link'>Utility Management</div>
                  <div onClick={() => navigate("/Security")} className='head_main_links_cont_link'>Security Serivces</div>
                  <div onClick={() => navigate("/ITES")} className='head_main_links_cont_link'>ITES</div>
                </div>
              )}
              </div>
              <div onClick={() => navigate("/Industry")} className='head_main_links_div'>Industries</div>
              <div onClick={() => navigate("/NewsAndEvents")} className='head_main_links_div'>News & Events</div>
              <div onClick={() => navigate("/Career")} className='head_main_links_div'>Career</div>
              <div onClick={() => navigate("/Contact")} className='head_main_links_div'>Contact Us</div>
          </div>
      </div>

      <div className='head_main mobile'>
          <div className='head_logo'><img src={logo} alt="logo" /></div>
          <div className='head_menu_btn' onClick={() => setMenuBtn(true)}><img src={menu} alt="menu" /></div>
          {menuBtn && (
              <>
              <div className='mobile_cont'><img src={close} alt='close' onClick={() => setMenuBtn(false)} /></div>
              <div className='head_mobile_link'>
                  <div onClick={() => navigate("/")} className='head_main_links_div activeLinkHead'>Home</div>
                  <div className='head_main_links_div'>About Us</div>
                  <div className='head_main_links_cont'>
                      <div onClick={() => navigate("/CompanyProfile")} className='head_main_links_cont_link'>Company Profile</div>
                      <div onClick={() => navigate("/JourneyAndGrowth")} className='head_main_links_cont_link'>Magnum Journey & Growth</div>
                      <div onClick={() => navigate("/Teams")} className='head_main_links_cont_link'>Magnum Team</div>
                  </div>
                  <div className='head_main_links_div'>Services</div>
                  <div className='head_main_links_cont'>
                      <div onClick={() => navigate("/BPO")} className='head_main_links_cont_link'>BPO</div>
                      <div onClick={() => navigate("/KPO")} className='head_main_links_cont_link'>KPO</div>
                      <div onClick={() => navigate("/OperationAndMain")} className='head_main_links_cont_link'>Operation & Maintanance</div>
                      <div onClick={() => navigate("/Utility")} className='head_main_links_cont_link'>Utility Management</div>
                      <div onClick={() => navigate("/Security")} className='head_main_links_cont_link'>Security Serivces</div>
                      <div onClick={() => navigate("/ITES")} className='head_main_links_cont_link'>ITES</div>
                  </div>
                  <div onClick={() => navigate("/Industry")} className='head_main_links_div'>Industries</div>
                  <div onClick={() => navigate("/NewsAndEvents")} className='head_main_links_div'>News & Events</div>
                  <div onClick={() => navigate("/Career")} className='head_main_links_div'>Career</div>
                  <div onClick={() => navigate("/Contact")} className='head_main_links_div'>Contact Us</div>
                  
                  
              </div>
              </>
          )}
      </div>

        <div className='banCont_2' data-aos="fade-up">
        <div className='banTitleCont_2'>
          <div className='banTitInner_2'>
            <div className='banTit1_2 f48'>Navigating the Future of Outsourcing: Proven BPO Solutions</div>
          </div>
        </div>
        <img src={im12} className='banImg_2' alt="banner" />
      </div>

        
        {/* comp 8 */}
        <div className='c1_1 c1_1_ext' style={{paddingBottom: 0, marginTop: 20}} data-aos="fade-up">
            <div className='c8'>Business Process Outsourcing</div>
            <div className='c22'>Primary companies have been using our customized BPO solutions to have an 
edge in the market. Through our BPO solutions, you get to save costs immediately
 along with the option of making enhancements that make businesses work smarter. 
With the right association with you BPO partner, valuable resources 
can be more economically utilized.</div>
        </div>

        {/* comp 4 */}
        <div className='c1_1 c1_1_ext' data-aos="fade-up">
            <div className='cp_0'>

                <div className='cp0 desktop'>
                    <div className='cp01'>
                        <div className='c4'>Inbound & Outbound Customer Care</div>
                        <div className='cp012'>We have expertise in providing BPO solutions 
                            to Top-tier Gov institutions, Global Telecom 
                            giants, nationalized Fin-Tech organizations, 
                            Retail giants,E-commerce industry leaders, 
                            Pan scale Textiles, Healthcare and Hospitality 
                            industry across India with overall 1.7 lakhs 
                            customer interaction per day with multi linguistic 
                            support in languages like Hindi, English Marathi, 
                            Gujarati, Kannada, Telugu, Tamil, Bengali & 
                            Assamese.
                        </div>
                    </div>
                    <div className='cp01'><img className='cp021' src={im13} alt='as' /></div>
                </div>

                <div className='cp0 mobile'>
                    <div className='cp01'><img className='cp021' src={im13} alt='as' /></div>
                    <div className='cp01'>
                        <div className='c4'>Inbound & Outbound Customer Care</div>
                        <div className='cp012'>We have expertise in providing BPO solutions 
                            to Top-tier Gov institutions, Global Telecom 
                            giants, nationalized Fin-Tech organizations, 
                            Retail giants,E-commerce industry leaders, 
                            Pan scale Textiles, Healthcare and Hospitality 
                            industry across India with overall 1.20 lakhs 
                            customer interaction per day with multi linguistic 
                            support in languages like Hindi, English Marathi, 
                            Gujarati, Kannada, Telugu, Tamil, Bengali & 
                            Assamese.
                        </div>
                    </div>
                </div>

            </div>
        </div>

        {/* comp 5 */}
        <div className='c1_1 c1_1_ext' style={{marginBottom: 80}} data-aos="fade-up">
            <div className='cp_0'>
                <div className='cp0'>
                    <div className='cp01'><img className='cp021' src={im14} alt='as' /></div>
                    <div className='cp01'>
                        <div className='c4'>BPO Recruitment Expertise</div>
                        <div className='c32'>We ensure right skillset of employees hired for a right job on right time with right pay to deliver right results.</div>
                        <div className='c33'><span></span> Market Research</div>
                        <div className='c33'><span></span> Skillset evalution</div>
                        <div className='c33'><span></span> Muiltilayer screening of candidates</div>
                        <div className='c33'><span></span> Background Verification</div>
                    </div>
                </div>
            </div>
        </div>

        <Footer />

        
    </>

  );
}

export default BPO;
