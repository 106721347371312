import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './screens/Main.css'
// basic routes

import Home from './screens/Home'
import Teams from './screens/Teams'
import CompanyProfile from './screens/CompanyProfile'
import JourneyAndGrowth from './screens/Journey'
import BPO from './screens/BPO'
import KPO from './screens/KPO'
import OperationAndMain from './screens/O&M'
import Utility from './screens/Utility'
import Security from './screens/Security'
import ITES from './screens/ITES'
import Industries from './screens/Industry'
import NewsAndEvents from './screens/News'
import Career from './screens/Carrer'
import Contact from './screens/Contact'
// import Privacy from './screens/Privacy'

import Upload from './screens/uploadfile'

function App() {
  useEffect(() => {
    document.title = 'Magnum Group'
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/Teams" element={<Teams />}></Route>
        <Route path="/CompanyProfile" element={<CompanyProfile />}></Route>
        <Route path="/BPO" element={<BPO />}></Route>
        <Route path="/KPO" element={<KPO />}></Route>
        <Route path="/OperationAndMain" element={<OperationAndMain />}></Route>
        <Route path="/Utility" element={<Utility />}></Route>
        <Route path="/Security" element={<Security />}></Route>
        <Route path="/ITES" element={<ITES />}></Route>
        <Route path="/Industry" element={<Industries />}></Route>
        <Route path="/NewsAndEvents" element={<NewsAndEvents />}></Route>
        <Route path="/Career" element={<Career />}></Route>
        <Route path="/Contact" element={<Contact />}></Route>
        <Route path="/JourneyAndGrowth" element={<JourneyAndGrowth />}></Route>
        {/* <Route path="/Privacy" element={<Privacy />}></Route> */}

        <Route path="/Upload" element={<Upload />}></Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
