import logo from '../images/logo/MagnumLogo.png';
import menu from '../images/icons/menu.svg';
import close from '../images/icons/close.svg';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

// import Header from '../components/Header';
// import ban1 from '../images/banner/banner1.png';
// import img1 from '../images/img/img1.png';
// import comp2Img from '../images/img/comp2.png';
import Footer from '../components/Footer';

import im26 from '../images/img/26.png';
import im27 from '../images/img/27.png';
import im28 from '../images/img/28.png';
import im29 from '../images/img/29.png';

import SecurityIcon from '@mui/icons-material/Security';
import DataObjectIcon from '@mui/icons-material/DataObject';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import SixtyFpsSelectIcon from '@mui/icons-material/SixtyFpsSelect';

function Security() {

    const navigate = useNavigate()
  const [menuBtn, setMenuBtn] = useState(false);
  const [menu1, setMenu1] = useState(false);
  const [menu2, setMenu2] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (

    <>
    
    <div className='head_main desktop'>
          <div className='head_logo'><img src={logo} alt="logo" /></div>
          <div className='head_main_links'>
              <div onClick={() => navigate("/")} className='head_main_links_div '>Home</div>
              <div className='head_main_links_div' onMouseOver={() => setMenu1(true)} onMouseLeave={() => setMenu1(false)}>About Us
              {menu1 && (
                  <div className='head_main_links_cont' onMouseOver={() => setMenu1(true)} onMouseLeave={() => setMenu1(false)}>
                      <div onClick={() => navigate("/CompanyProfile")} className='head_main_links_cont_link'>Company Profile</div>
                      <div onClick={() => navigate("/JourneyAndGrowth")} className='head_main_links_cont_link'>Magnum Journey & Growth</div>
                      <div onClick={() => navigate("/Teams")} className='head_main_links_cont_link'>Magnum Team</div>
                  </div>
              )}
              </div>
              <div className='head_main_links_div' onMouseOver={() => setMenu2(true)} onMouseLeave={() => setMenu2(false)}>Services
              {menu2 && (
                <div className='head_main_links_cont' onMouseOver={() => setMenu2(true)} onMouseLeave={() => setMenu2(false)}>
                  <div onClick={() => navigate("/BPO")} className='head_main_links_cont_link'>BPO</div>
                  <div onClick={() => navigate("/KPO")} className='head_main_links_cont_link'>KPO</div>
                  <div onClick={() => navigate("/OperationAndMain")} className='head_main_links_cont_link'>Operation & Maintanance</div>
                  <div onClick={() => navigate("/Utility")} className='head_main_links_cont_link'>Utility Management</div>
                  <div onClick={() => navigate("/Security")} className='head_main_links_cont_link activeLinkHead'>Security Serivces</div>
                  <div onClick={() => navigate("/ITES")} className='head_main_links_cont_link'>ITES</div>
                </div>
              )}
              </div>
              <div onClick={() => navigate("/Industry")} className='head_main_links_div'>Industries</div>
              <div onClick={() => navigate("/NewsAndEvents")} className='head_main_links_div'>News & Events</div>
              <div onClick={() => navigate("/Career")} className='head_main_links_div'>Career</div>
              <div onClick={() => navigate("/Contact")} className='head_main_links_div'>Contact Us</div>
          </div>
      </div>

      <div className='head_main mobile'>
          <div className='head_logo'><img src={logo} alt="logo" /></div>
          <div className='head_menu_btn' onClick={() => setMenuBtn(true)}><img src={menu} alt="menu" /></div>
          {menuBtn && (
              <>
              <div className='mobile_cont'><img src={close} alt='close' onClick={() => setMenuBtn(false)} /></div>
              <div className='head_mobile_link'>
                  <div onClick={() => navigate("/")} className='head_main_links_div activeLinkHead'>Home</div>
                  <div className='head_main_links_div'>About Us</div>
                  <div className='head_main_links_cont'>
                      <div onClick={() => navigate("/CompanyProfile")} className='head_main_links_cont_link'>Company Profile</div>
                      <div onClick={() => navigate("/JourneyAndGrowth")} className='head_main_links_cont_link'>Magnum Journey & Growth</div>
                      <div onClick={() => navigate("/Teams")} className='head_main_links_cont_link'>Magnum Team</div>
                  </div>
                  <div className='head_main_links_div'>Services</div>
                  <div className='head_main_links_cont'>
                      <div onClick={() => navigate("/BPO")} className='head_main_links_cont_link'>BPO</div>
                      <div onClick={() => navigate("/KPO")} className='head_main_links_cont_link'>KPO</div>
                      <div onClick={() => navigate("/OperationAndMain")} className='head_main_links_cont_link'>Operation & Maintanance</div>
                      <div onClick={() => navigate("/Utility")} className='head_main_links_cont_link'>Utility Management</div>
                      <div onClick={() => navigate("/Security")} className='head_main_links_cont_link'>Security Serivces</div>
                      <div onClick={() => navigate("/ITES")} className='head_main_links_cont_link'>ITES</div>
                  </div>
                  <div onClick={() => navigate("/Industry")} className='head_main_links_div'>Industries</div>
                  <div onClick={() => navigate("/NewsAndEvents")} className='head_main_links_div'>News & Events</div>
                  <div onClick={() => navigate("/Career")} className='head_main_links_div'>Career</div>
                  <div onClick={() => navigate("/Contact")} className='head_main_links_div'>Contact Us</div>
                  
                  
              </div>
              </>
          )}
      </div>

        <div className='banCont_2' data-aos="fade-up">
        <div className='banTitleCont_2'>
          <div className='banTitInner_2'>
          <div className='banTit1_2'>Trusted Security Solutions</div>
            <div className='banTit2_2'>Reliable, Resilient, Ready</div>
          </div>
        </div>
        <img src={im26} className='banImg_2' alt="banner" />
      </div>


        
        {/* comp 8 */}
        <div className='c1_1' style={{paddingBottom: 0, marginTop: 20, marginBottom: 40}} data-aos="fade-up">
            <div className='c8'>SECURITY SERVICES</div>
            <div className='c22'>Our consistent engagement with clients like ACC, HEG, Crompton Greaves, and others over a span of
20 years highlights the unwavering reliability of our professional bonding.</div>
        </div>

        {/* comp 6 */}
        <div className='c6' data-aos="fade-up">

            <div className='c61'>
                <div className='c611'><img className='c6111' src={im27} alt='as' /></div>
                <div className='c612'>
                    <div className='cp011'>Security</div>
                    <div className='c32'>From controlling access, protecting intellectual property, 
reducing internal theft, securing both circuit and packet 
switched networks, or safeguarding people and assets, 
utilities and communications security.</div>
                </div>
            </div>

            <div className='c61 desktop'>
                <div className='c612'>
                    <div className='cp011'>Surveillance</div>
                    <div className='c32'>From offsite monitoring of video surveillance, 
building controls, and intrusion detection 
systems to provide greater awareness and 
verification of alarm activations and incidents.</div>
                </div>
                <div className='c611'><img className='c6111' src={im28} alt='as' /></div>
            </div>
            <div className='c61 mobile'>
                <div className='c611'><img className='c6111' src={im28} alt='as' /></div>
                <div className='c612'>
                    <div className='cp011'>Surveillance</div>
                    <div className='c32'>From offsite monitoring of video surveillance, 
building controls, and intrusion detection 
systems to provide greater awareness and 
verification of alarm activations and incidents.</div>
                </div>
            </div>

        </div>

        {/* comp 6 */}
        <div className='c6'  data-aos="fade-up">

            <div className='c61'>
                <div className='c611'><img className='c6111' src={im29} alt='as' /></div>
                <div className='c612'>
                    <div className='cp011'>Patrolling</div>
                    <div className='c32'>From mobile patrols and alarm response, consistency 
and speed are key. Using latest technology in database 
management, mobile communications, navigation & GPS, 
we coordinate seamless, end-to-end security.</div>
                </div>
            </div>

        </div>

        {/* core values */}
        <div className='c2'  data-aos="fade-up">
            <div className='c21'>KEY SERVICES OFFERED BY OUR SECURITY OFFICERS</div>
            <div className='c22'>We place client at the core of everything we do.</div>
            <div className='c23'>

                <div className='c231_c'>
                    <div className='c231'><SecurityIcon /></div>
                    <div className='c232'>PRECLUSION</div>
                    <div className='c233'>Committed towards providing a substantial prevention to unlawful and unsuitable activity at the location of assignment.</div>
                </div>
                <div className='c231_c'>
                    <div className='c231'><DataObjectIcon /></div>
                    <div className='c232'>OBSERVATION AND REPORTING</div>
                    <div className='c233'>Aiming at completing multi
disciplinary tasks & providing 
value added services through 
our in-house expertise</div>
                </div>
                <div className='c231_c'>
                    <div className='c231'><EnhancedEncryptionIcon /></div>
                    <div className='c232'>SAFETY & SECURITY</div>
                    <div className='c233'>Magnum’s security officers implement proper safety policies and take all required actions against safety hazards.</div>
                </div>

            </div>
            <div className='c23'>

                <div className='c231_c'>
                    <div className='c231'><HealthAndSafetyIcon /></div>
                    <div className='c232'>PROTECTION</div>
                    <div className='c233'>As a trained staff, security officers are responsible for protecting people, property & law including the apprehension of suspects and the use of powers when necessary.</div>
                </div>
                <div className='c231_c'>
                    <div className='c231'><SixtyFpsSelectIcon /></div>
                    <div className='c232'>TAILOR MADE REQUIREMENTS</div>
                    <div className='c233'>Along with the basic expectations from security officers, our team can be explicitly trained to guard orders as per specific requirements.</div>
                </div>

            </div>
        </div>

        {/* comp 3 */}
        <div className='c3'>
            <div className='c31'>Well-groomed staff is a glory to our company</div>
            <div className='c32'>Most of our recruiting methods are quite innovative and ensures continuous value addition to meet customer’s expectations. Our team meets following basic requirements:</div>
            <div className='c33'><span></span> Register under Private Security Agency Regulation Act (PSARA)</div>
            <div className='c33'><span></span> Can speak, read and write Hindi & English properly</div>
            <div className='c33'><span></span> Have no criminal or lawbreaking record</div>
            <div className='c33'><span></span> Have legal Indian citizenship</div>
            <div className='c33'><span></span> Have no history or record of alcohol usage or other addiction</div>
            <div className='c33'><span></span> Emotionally and mentally capable of handling responsibilities</div>
            <div className='c33'><span></span> Verified professional record</div>
            <div className='c33'><span></span> Minimum required age is 18 years</div>
            <div className='c33'><span></span> All applicants are screened thoroughly</div>
        </div>

        <Footer />

        
    </>

  );
}

export default Security;
