import * as Yup from "yup";

export const feedbackFormSchema = Yup.object({
    name: Yup.string().min(2).max(25).required("Please enter your name"),
    email: Yup.string().email().required("Please enter your email"),
    contact: Yup.number().min(10).required("Please enter your contact Number"),
    text: Yup.string().min(5).max(100).required("Please enter your message")
});

export const careenFormSchema = Yup.object({
    f_name: Yup.string().min(2).max(25).required("Please enter your first name"),
    l_name: Yup.string().min(2).max(25).required("Please enter your last name"),
    email: Yup.string().email().required("Please enter your email"),
    contact: Yup.number().min(10).required("Please enter your contact Number"),
    dob: Yup.date().required("Please enter your date of birth"),
    work_exp: Yup.number().required("Please enter your work experience"),
    area_of_exp: Yup.string().max(100).required("Please enter your area of expertise"),
});