import Header from '../components/Header';
import ban1 from '../images/banner/banner1.png';

import logo from '../images/logo/MagnumLogo.png';
import menu from '../images/icons/menu.svg';
import close from '../images/icons/close.svg';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import AddLocation from '@mui/icons-material/AddLocation';
import Typography from '@mui/material/Typography';

import im11 from '../images/img/11.png';
import Footer from '../components/Footer';

function Security() {

    const navigate = useNavigate()
  const [menuBtn, setMenuBtn] = useState(false);
  const [menu1, setMenu1] = useState(false);
  const [menu2, setMenu2] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (

    <>
    
    <div className='head_main desktop'>
          <div className='head_logo'><img src={logo} alt="logo" /></div>
          <div className='head_main_links'>
              <div onClick={() => navigate("/")} className='head_main_links_div '>Home</div>
              <div className='head_main_links_div' onMouseOver={() => setMenu1(true)} onMouseLeave={() => setMenu1(false)}>About Us
              {menu1 && (
                  <div className='head_main_links_cont' onMouseOver={() => setMenu1(true)} onMouseLeave={() => setMenu1(false)}>
                      <div onClick={() => navigate("/CompanyProfile")} className='head_main_links_cont_link'>Company Profile</div>
                      <div onClick={() => navigate("/JourneyAndGrowth")} className='head_main_links_cont_link activeLinkHead'>Magnum Journey & Growth</div>
                      <div onClick={() => navigate("/Teams")} className='head_main_links_cont_link'>Magnum Team</div>
                  </div>
              )}
              </div>
              <div className='head_main_links_div' onMouseOver={() => setMenu2(true)} onMouseLeave={() => setMenu2(false)}>Services
              {menu2 && (
                <div className='head_main_links_cont' onMouseOver={() => setMenu2(true)} onMouseLeave={() => setMenu2(false)}>
                  <div onClick={() => navigate("/BPO")} className='head_main_links_cont_link'>BPO</div>
                  <div onClick={() => navigate("/KPO")} className='head_main_links_cont_link'>KPO</div>
                  <div onClick={() => navigate("/OperationAndMain")} className='head_main_links_cont_link'>Operation & Maintanance</div>
                  <div onClick={() => navigate("/Utility")} className='head_main_links_cont_link'>Utility Management</div>
                  <div onClick={() => navigate("/Security")} className='head_main_links_cont_link'>Security Serivces</div>
                  <div onClick={() => navigate("/ITES")} className='head_main_links_cont_link'>ITES</div>
                </div>
              )}
              </div>
              <div onClick={() => navigate("/Industry")} className='head_main_links_div'>Industries</div>
              <div onClick={() => navigate("/NewsAndEvents")} className='head_main_links_div'>News & Events</div>
              <div onClick={() => navigate("/Career")} className='head_main_links_div'>Career</div>
              <div onClick={() => navigate("/Contact")} className='head_main_links_div'>Contact Us</div>
          </div>
      </div>

      <div className='head_main mobile'>
          <div className='head_logo'><img src={logo} alt="logo" /></div>
          <div className='head_menu_btn' onClick={() => setMenuBtn(true)}><img src={menu} alt="menu" /></div>
          {menuBtn && (
              <>
              <div className='mobile_cont'><img src={close} alt='close' onClick={() => setMenuBtn(false)} /></div>
              <div className='head_mobile_link'>
                  <div onClick={() => navigate("/")} className='head_main_links_div activeLinkHead'>Home</div>
                  <div className='head_main_links_div'>About Us</div>
                  <div className='head_main_links_cont'>
                      <div onClick={() => navigate("/CompanyProfile")} className='head_main_links_cont_link'>Company Profile</div>
                      <div onClick={() => navigate("/JourneyAndGrowth")} className='head_main_links_cont_link'>Magnum Journey & Growth</div>
                      <div onClick={() => navigate("/Teams")} className='head_main_links_cont_link'>Magnum Team</div>
                  </div>
                  <div className='head_main_links_div'>Services</div>
                  <div className='head_main_links_cont'>
                      <div onClick={() => navigate("/BPO")} className='head_main_links_cont_link'>BPO</div>
                      <div onClick={() => navigate("/KPO")} className='head_main_links_cont_link'>KPO</div>
                      <div onClick={() => navigate("/OperationAndMain")} className='head_main_links_cont_link'>Operation & Maintanance</div>
                      <div onClick={() => navigate("/Utility")} className='head_main_links_cont_link'>Utility Management</div>
                      <div onClick={() => navigate("/Security")} className='head_main_links_cont_link'>Security Serivces</div>
                      <div onClick={() => navigate("/ITES")} className='head_main_links_cont_link'>ITES</div>
                  </div>
                  <div onClick={() => navigate("/Industry")} className='head_main_links_div'>Industries</div>
                  <div onClick={() => navigate("/NewsAndEvents")} className='head_main_links_div'>News & Events</div>
                  <div onClick={() => navigate("/Career")} className='head_main_links_div'>Career</div>
                  <div onClick={() => navigate("/Contact")} className='head_main_links_div'>Contact Us</div>
                  
                  
              </div>
              </>
          )}
      </div>

{/* /banner */}
<div className='banCont_2' data-aos="fade-up">
        <div className='banTitleCont_2'>
          <div className='banTitInner_2'>
            <div className='banTit1_2'>From Vision <br />to Victory</div>
            {/* <div className='banTit2_2'>From Vision to Victory: Our Journey and Growth Path</div> */}
          </div>
        </div>
        <img src={im11} className='banImg_2' alt="banner" />
      </div>


        {/* comp 8 */}
        <div className='c1_1' data-aos="fade-up">
            <div className='c8'>OUR JOURNEY & GROWTH PLAN</div>
        </div>

        <Timeline position="alternate" className='timeline'>
            <TimelineItem>
                <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
                color="text.secondary"
                >
                In 2020
                </TimelineOppositeContent>
                <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="primary">
                    <AddLocation />
                </TimelineDot>
                <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h4" component="span">
                2020-2023
                </Typography>
                <Typography data-aos="fade-left">We have added a new line of businesses and with fruitful efforts driven by a thirst for excellence, we have grown exponentially over the last year exceeding our expected threshold, making it a landmark achievement and setting an example for our future endeavours.</Typography>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                variant="body2"
                color="text.secondary"
                >
                In 2018
                </TimelineOppositeContent>
                <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="primary">
                    <AddLocation />
                </TimelineDot>
                <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h4" component="span">
                2018-2020
                </Typography>
                <Typography data-aos="fade-right">Our efforts & determination has led us to be the premier and preferred provider of business processing outsourcing, knowledge process outsourcing, operations and maintenance, utility management, security services in our chosen market. We are 4500 as a team.</Typography>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
                color="text.secondary"
                >
                In 2016
                </TimelineOppositeContent>
                <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="primary">
                    <AddLocation />
                </TimelineDot>
                <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h4" component="span">
                2016
                </Typography>
                <Typography data-aos="fade-left">We are growing, we are focussing on our service quality, we are sensitive to our environment, we are committed to our responsibilities, we are 3000 as a team.</Typography>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                variant="body2"
                color="text.secondary"
                >
                In 2013
                </TimelineOppositeContent>
                <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="primary">
                    <AddLocation />
                </TimelineDot>
                <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h4" component="span">
                2013
                </Typography>
                <Typography data-aos="fade-right">As our vision continues to shape itself, we broaden our outlook to services and added BPO, O&M and utility services to our portfolio. Reliance telecom and Vodafone could increase our employee to 2200.</Typography>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
                color="text.secondary"
                >
                In 2008
                </TimelineOppositeContent>
                <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="primary">
                    <AddLocation />
                </TimelineDot>
                <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h4" component="span">
                2008
                </Typography>
                <Typography data-aos="fade-left">In the subsequent years, we focussed on structure and quality of our services and explored new avenues. We ventured into Telecom security and diamond mining. Measures of success were the contracts with Idea, BSNL and Bharti Infratel, employee strength of 850 and revenue grossing to INR 20 Cr.</Typography>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                variant="body2"
                color="text.secondary"
                >
                In 2003
                </TimelineOppositeContent>
                <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="primary">
                    <AddLocation />
                </TimelineDot>
                <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h4" component="span">
                2003
                </Typography>
                <Typography data-aos="fade-right">Contract from HEG opened new avenues and our count became 680.</Typography>
                </TimelineContent>
            </TimelineItem><TimelineItem>
                <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
                color="text.secondary"
                >
                In 1998
                </TimelineOppositeContent>
                <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="primary">
                    <AddLocation />
                </TimelineDot>
                <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h4" component="span">
                1998
                </Typography>
                <Typography data-aos="fade-left">Company was being recognised by corporate alike Crompton Greaves. we could add to 380 in our team.</Typography>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                variant="body2"
                color="text.secondary"
                >
                In 1993
                </TimelineOppositeContent>
                <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="primary">
                    <AddLocation />
                </TimelineDot>
                <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h4" component="span">
                1993
                </Typography>
                <Typography data-aos="fade-right">With a modest start up in 1993, we were 55. ACC responded to our vision and services for Industrial Security.</Typography>
                </TimelineContent>
            </TimelineItem>
        </Timeline>

        <Footer />

        
    </>

  );
}

export default Security;
