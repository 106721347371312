import React, { useEffect, useState } from 'react';
import logo from '../images/logo/MagnumLogo.png';
import menu from '../images/icons/menu.svg';
import close from '../images/icons/close.svg';

function Header() {

    const [menuBtn, setMenuBtn] = useState(false);

  return (

    <>
    
        {/* desktop */}
        <div className='head_main desktop'>
            <div className='head_logo'><img src={logo} alt="logo" /></div>
            <div className='head_main_links'>
                <div className='head_main_links_div'>Home</div>
                <div className='head_main_links_div'>About Us
                    <div className='head_main_links_cont'>
                        <div className='head_main_links_cont_link'>Company Profile</div>
                        <div className='head_main_links_cont_link'>Magnum Journey & Growth</div>
                        <div className='head_main_links_cont_link'>Megnum Team</div>
                    </div>
                </div>
                <div className='head_main_links_div'>Services
                    {/* <div className='head_main_links_cont'>
                        <div className='head_main_links_cont_link'>BPO</div>
                        <div className='head_main_links_cont_link'>KPO</div>
                        <div className='head_main_links_cont_link'>Operation & Maintanance</div>
                        <div className='head_main_links_cont_link'>Utility Management</div>
                        <div className='head_main_links_cont_link'>Security Serivces</div>
                        <div className='head_main_links_cont_link'>ITES</div>
                    </div> */}
                </div>
                <div className='head_main_links_div'>Industries</div>
                <div className='head_main_links_div'>News & Events</div>
                <div className='head_main_links_div'>Career</div>
                <div className='head_main_links_div'>Contact Us</div>
            </div>
        </div>

        <div className='head_main mobile'>
            <div className='head_logo'><img src={logo} alt="logo" /></div>
            <div className='head_menu_btn' onClick={() => setMenuBtn(true)}><img src={menu} alt="menu" /></div>
            {menuBtn && (
                <div className='head_mobile_link'>
                    <img src={close} alt='close' onClick={() => setMenuBtn(false)} />
                    <div className='head_main_links_div'>Home</div>
                    <div className='head_main_links_div'>About Us</div>
                    <div className='head_main_links_div'>Industries</div>
                    <div className='head_main_links_div'>News & Events</div>
                    <div className='head_main_links_div'>Career</div>
                    <div className='head_main_links_div'>Contact Us</div>
                </div>
            )}
        </div>

    </>

  );
}

export default Header;

// 136338541
