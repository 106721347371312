import logo from '../images/logo/MagnumLogo.png';
import menu from '../images/icons/menu.svg';
import close from '../images/icons/close.svg';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Header from '../components/Header';
import ban1 from '../images/banner/banner1.png';
import img1 from '../images/img/img1.png';
import comp2Img from '../images/img/comp2.png';
import Footer from '../components/Footer';

import im21 from '../images/img/21.png';
import im22 from '../images/img/22.png';
import im23 from '../images/img/23.png';
import im24 from '../images/img/24.png';
import im25 from '../images/img/25.png';

function Utility() {

    const navigate = useNavigate()
  const [menuBtn, setMenuBtn] = useState(false);
  const [menu1, setMenu1] = useState(false);
  const [menu2, setMenu2] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (

    <>
    
    <div className='head_main desktop'>
          <div className='head_logo'><img src={logo} alt="logo" /></div>
          <div className='head_main_links'>
              <div onClick={() => navigate("/")} className='head_main_links_div '>Home</div>
              <div className='head_main_links_div' onMouseOver={() => setMenu1(true)} onMouseLeave={() => setMenu1(false)}>About Us
              {menu1 && (
                  <div className='head_main_links_cont' onMouseOver={() => setMenu1(true)} onMouseLeave={() => setMenu1(false)}>
                      <div onClick={() => navigate("/CompanyProfile")} className='head_main_links_cont_link'>Company Profile</div>
                      <div onClick={() => navigate("/JourneyAndGrowth")} className='head_main_links_cont_link'>Magnum Journey & Growth</div>
                      <div onClick={() => navigate("/Teams")} className='head_main_links_cont_link'>Magnum Team</div>
                  </div>
              )}
              </div>
              <div className='head_main_links_div' onMouseOver={() => setMenu2(true)} onMouseLeave={() => setMenu2(false)}>Services
              {menu2 && (
                <div className='head_main_links_cont' onMouseOver={() => setMenu2(true)} onMouseLeave={() => setMenu2(false)}>
                  <div onClick={() => navigate("/BPO")} className='head_main_links_cont_link'>BPO</div>
                  <div onClick={() => navigate("/KPO")} className='head_main_links_cont_link'>KPO</div>
                  <div onClick={() => navigate("/OperationAndMain")} className='head_main_links_cont_link'>Operation & Maintanance</div>
                  <div onClick={() => navigate("/Utility")} className='head_main_links_cont_link activeLinkHead'>Utility Management</div>
                  <div onClick={() => navigate("/Security")} className='head_main_links_cont_link'>Security Serivces</div>
                  <div onClick={() => navigate("/ITES")} className='head_main_links_cont_link'>ITES</div>
                </div>
              )}
              </div>
              <div onClick={() => navigate("/Industry")} className='head_main_links_div'>Industries</div>
              <div onClick={() => navigate("/NewsAndEvents")} className='head_main_links_div'>News & Events</div>
              <div onClick={() => navigate("/Career")} className='head_main_links_div'>Career</div>
              <div onClick={() => navigate("/Contact")} className='head_main_links_div'>Contact Us</div>
          </div>
      </div>

      <div className='head_main mobile'>
          <div className='head_logo'><img src={logo} alt="logo" /></div>
          <div className='head_menu_btn' onClick={() => setMenuBtn(true)}><img src={menu} alt="menu" /></div>
          {menuBtn && (
              <>
              <div className='mobile_cont'><img src={close} alt='close' onClick={() => setMenuBtn(false)} /></div>
              <div className='head_mobile_link'>
                  <div onClick={() => navigate("/")} className='head_main_links_div activeLinkHead'>Home</div>
                  <div className='head_main_links_div'>About Us</div>
                  <div className='head_main_links_cont'>
                      <div onClick={() => navigate("/CompanyProfile")} className='head_main_links_cont_link'>Company Profile</div>
                      <div onClick={() => navigate("/JourneyAndGrowth")} className='head_main_links_cont_link'>Magnum Journey & Growth</div>
                      <div onClick={() => navigate("/Teams")} className='head_main_links_cont_link'>Magnum Team</div>
                  </div>
                  <div className='head_main_links_div'>Services</div>
                  <div className='head_main_links_cont'>
                      <div onClick={() => navigate("/BPO")} className='head_main_links_cont_link'>BPO</div>
                      <div onClick={() => navigate("/KPO")} className='head_main_links_cont_link'>KPO</div>
                      <div onClick={() => navigate("/OperationAndMain")} className='head_main_links_cont_link'>Operation & Maintanance</div>
                      <div onClick={() => navigate("/Utility")} className='head_main_links_cont_link'>Utility Management</div>
                      <div onClick={() => navigate("/Security")} className='head_main_links_cont_link'>Security Serivces</div>
                      <div onClick={() => navigate("/ITES")} className='head_main_links_cont_link'>ITES</div>
                  </div>
                  <div onClick={() => navigate("/Industry")} className='head_main_links_div'>Industries</div>
                  <div onClick={() => navigate("/NewsAndEvents")} className='head_main_links_div'>News & Events</div>
                  <div onClick={() => navigate("/Career")} className='head_main_links_div'>Career</div>
                  <div onClick={() => navigate("/Contact")} className='head_main_links_div'>Contact Us</div>
                  
                  
              </div>
              </>
          )}
      </div>

        <div className='banCont_2' data-aos="fade-up">
        <div className='banTitleCont_2'>
          <div className='banTitInner_2'>
          <div className='banTit1_2'>Comprehensive Utility Management</div>
            <div className='banTit2_2'>Simplify, Optimize, Succeed</div>
          </div>
        </div>
        <img src={im21} className='banImg_2' alt="banner" />
      </div>


        
        {/* comp 8 */}
        <div className='c1_1' style={{paddingBottom: 0, marginTop: 20, marginBottom: 40}} data-aos="fade-up">
            <div className='c8'>GETTING TO KNOW UTILITIES</div>
            <div className='c22'>Utility companies play a major role in managing the infrastructure that 
provides vital services to communities, including electricity, gas and water. 
If we look at the energy market in most countries, the utility sector is usually
split between different types of activity.</div>
        </div>

        {/* comp 6 */}
        <div className='c6' data-aos="fade-up">

            <div className='c61'>
                <div className='c611'><img className='c6111' src={im22} alt='as' /></div>
                <div className='c612'>
                    <div className='cp011'>Facility Management Services</div>
                    <div className='c33'><span></span> Housekeeping services – Corporate</div>
                    <div className='c33'><span></span> Janitorial services</div>
                    <div className='c33'><span></span> Pantry management</div>
                    <div className='c33'><span></span> Pest control & garden maintenance</div>
                    <div className='c33'><span></span> Guest house management</div>
                </div>
            </div>

            <div className='c61 desktop'>
                <div className='c612'>
                    <div className='cp011'>Technical Engineering Services</div>
                    <div className='c33'><span></span> Electrical O&M Services</div>
                    <div className='c33'><span></span> IME Services</div>
                    <div className='c33'><span></span> Utility O&M Services- Industries</div>
                    <div className='c33'><span></span> Project Management</div>
                    <div className='c33'><span></span> Project Consultancy Service</div>
                </div>
                <div className='c611'><img className='c6111' src={im23} alt='as' /></div>
            </div>
            <div className='c61 mobile'>
                <div className='c611'><img className='c6111' src={im23} alt='as' /></div>
                <div className='c612'>
                    <div className='cp011'>Technical Engineering Services</div>
                    <div className='c33'><span></span> Electrical O&M Services</div>
                    <div className='c33'><span></span> IME Services</div>
                    <div className='c33'><span></span> Utility O&M Services- Industries</div>
                    <div className='c33'><span></span> Project Management</div>
                    <div className='c33'><span></span> Project Consultancy Service</div>
                </div>
            </div>

        </div>

        {/* comp 6 */}
        <div className='c6' data-aos="fade-up">

            <div className='c61'>
                <div className='c611'><img className='c6111' src={im24} alt='as' /></div>
                <div className='c612'>
                    <div className='cp011'>Support Services</div>
                    <div className='c33'><span></span> Skilled and semiskilled manpower for Industries</div>
                    <div className='c33'><span></span> Unskilled manpower</div>
                    <div className='c33'><span></span> Outsourced manpower for Logistics</div>
                    <div className='c33'><span></span> Office support staff viz, Front Office, 
Accounts and Human Resource.</div>
                </div>
            </div>

            <div className='c61 desktop'>
                <div className='c612'>
                    <div className='cp011'>Specialized Services</div>
                    <div className='c33'><span></span> One time cleaning works for residences</div>
                    <div className='c33'><span></span> Post construction cleaning works</div>
                    <div className='c33'><span></span> IT room cleaning</div>
                    <div className='c33'><span></span> Potted plants refurbishing and maintenance</div>
                    <div className='c33'><span></span> Shampooing of carpets</div>
                </div>
                <div className='c611'><img className='c6111' src={im25} alt='as' /></div>
            </div>
            <div className='c61 mobile'>
                <div className='c611'><img className='c6111' src={im25} alt='as' /></div>
                <div className='c612'>
                    <div className='cp011'>Specialized Services</div>
                    <div className='c33'><span></span> One time cleaning works for residences</div>
                    <div className='c33'><span></span> Post construction cleaning works</div>
                    <div className='c33'><span></span> IT room cleaning</div>
                    <div className='c33'><span></span> Potted plants refurbishing and maintenance</div>
                    <div className='c33'><span></span> Shampooing of carpets</div>
                </div>
            </div>

        </div>

        <Footer />

        
    </>

  );
}

export default Utility;
