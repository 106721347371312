import logo from '../images/logo/MagnumLogo.png';
import menu from '../images/icons/menu.svg';
import close from '../images/icons/close.svg';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';

// import Header from '../components/Header';
// import ban1 from '../images/banner/banner1.png';
// import img1 from '../images/img/img1.png';
// import comp2Img from '../images/img/comp2.png';
import Footer from '../components/Footer';

import l11 from '../images/LOBs/Telecom/Airtel.png';
import l12 from '../images/LOBs/Telecom/BSNL.png';
import l13 from '../images/LOBs/Telecom/Idea.png';
import l14 from '../images/LOBs/Telecom/Rcom.png';

import l21 from '../images/LOBs/E-Commerce/DB Homeonline.png';
import l22 from '../images/LOBs/E-Commerce/Flyrobe.png';
import l23 from '../images/LOBs/E-Commerce/Gem.png';
import l24 from '../images/LOBs/E-Commerce/Labouradda.png';
import l25 from '../images/LOBs/E-Commerce/Limeroad.png';
import l26 from '../images/LOBs/E-Commerce/Meesho.png';
import l27 from '../images/LOBs/E-Commerce/Noon.png';
import l28 from '../images/LOBs/E-Commerce/Swiggy.png';
import l29 from '../images/LOBs/E-Commerce/iplan.png';

import l31 from '../images/LOBs/E-Governance/BMC.png';
import l32 from '../images/LOBs/E-Governance/CMHL.png';
import l33 from '../images/LOBs/E-Governance/DMC.png';
import l34 from '../images/LOBs/E-Governance/Handicraft.png';
import l35 from '../images/LOBs/E-Governance/Hanldoom.png';
import l36 from '../images/LOBs/E-Governance/JSDMS.png';
import l37 from '../images/LOBs/E-Governance/Jansamvaad.png';
import l38 from '../images/LOBs/E-Governance/Jharkhand.png';
import l39 from '../images/LOBs/E-Governance/MPPKVVCL.png';
import l310 from '../images/LOBs/E-Governance/Micromax.png';
import l311 from '../images/LOBs/E-Governance/pwc.png';
import l312 from '../images/LOBs/E-Governance/SKY.png';

import l41 from '../images/LOBs/Digital TV & Others/Bansal.png';
import l42 from '../images/LOBs/Digital TV & Others/DB.png';
import l43 from '../images/LOBs/Digital TV & Others/Fancode.png';
import l44 from '../images/LOBs/Digital TV & Others/MP Birla Group.png';
import l45 from '../images/LOBs/Digital TV & Others/Reliance Tv .png';
import l46 from '../images/LOBs/Digital TV & Others/Reliance cement .png';

import l51 from '../images/LOBs/BFSI/AV.png';
import l52 from '../images/LOBs/BFSI/Indian Money.png';
import l53 from '../images/LOBs/BFSI/Mpokket.png';
import l54 from '../images/LOBs/BFSI/Niyo.png';
import l55 from '../images/LOBs/BFSI/Paytm.png';
import l56 from '../images/LOBs/BFSI/Reliance Nippon Insurance.png';
import l57 from '../images/LOBs/BFSI/Zest.png';

import im31 from '../images/img/31.png';
import im33 from '../images/img/33.png';
import im34 from '../images/img/34.png';
import im35 from '../images/img/35.png';
import im36 from '../images/img/36.png';
import im37 from '../images/img/37.png';



function Industry() {

  const navigate = useNavigate()
  const [menuBtn, setMenuBtn] = useState(false);
  const [menu1, setMenu1] = useState(false);
  const [menu2, setMenu2] = useState(false);


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [l1, setL1] = useState(false);
  const [l2, setL2] = useState(false);
  const [l3, setL3] = useState(false);
  const [l4, setL4] = useState(false);
  const [l5, setL5] = useState(false);

  return (

    <>
    
    <div className='head_main desktop'>
          <div className='head_logo'><img src={logo} alt="logo" /></div>
          <div className='head_main_links'>
              <div onClick={() => navigate("/")} className='head_main_links_div '>Home</div>
              <div className='head_main_links_div' onMouseOver={() => setMenu1(true)} onMouseLeave={() => setMenu1(false)}>About Us
              {menu1 && (
                  <div className='head_main_links_cont' onMouseOver={() => setMenu1(true)} onMouseLeave={() => setMenu1(false)}>
                      <div onClick={() => navigate("/CompanyProfile")} className='head_main_links_cont_link'>Company Profile</div>
                      <div onClick={() => navigate("/JourneyAndGrowth")} className='head_main_links_cont_link'>Magnum Journey & Growth</div>
                      <div onClick={() => navigate("/Teams")} className='head_main_links_cont_link'>Magnum Team</div>
                  </div>
              )}
              </div>
              <div className='head_main_links_div' onMouseOver={() => setMenu2(true)} onMouseLeave={() => setMenu2(false)}>Services
              {menu2 && (
                <div className='head_main_links_cont' onMouseOver={() => setMenu2(true)} onMouseLeave={() => setMenu2(false)}>
                  <div onClick={() => navigate("/BPO")} className='head_main_links_cont_link'>BPO</div>
                  <div onClick={() => navigate("/KPO")} className='head_main_links_cont_link'>KPO</div>
                  <div onClick={() => navigate("/OperationAndMain")} className='head_main_links_cont_link'>Operation & Maintanance</div>
                  <div onClick={() => navigate("/Utility")} className='head_main_links_cont_link'>Utility Management</div>
                  <div onClick={() => navigate("/Security")} className='head_main_links_cont_link'>Security Serivces</div>
                  <div onClick={() => navigate("/ITES")} className='head_main_links_cont_link'>ITES</div>
                </div>
              )}
              </div>
              <div onClick={() => navigate("/Industry")} className='head_main_links_div activeLinkHead'>Industries</div>
              <div onClick={() => navigate("/NewsAndEvents")} className='head_main_links_div'>News & Events</div>
              <div onClick={() => navigate("/Career")} className='head_main_links_div'>Career</div>
              <div onClick={() => navigate("/Contact")} className='head_main_links_div'>Contact Us</div>
          </div>
      </div>

      <div className='head_main mobile'>
          <div className='head_logo'><img src={logo} alt="logo" /></div>
          <div className='head_menu_btn' onClick={() => setMenuBtn(true)}><img src={menu} alt="menu" /></div>
          {menuBtn && (
              <>
              <div className='mobile_cont'><img src={close} alt='close' onClick={() => setMenuBtn(false)} /></div>
              <div className='head_mobile_link'>
                  <div onClick={() => navigate("/")} className='head_main_links_div activeLinkHead'>Home</div>
                  <div className='head_main_links_div'>About Us</div>
                  <div className='head_main_links_cont'>
                      <div onClick={() => navigate("/CompanyProfile")} className='head_main_links_cont_link'>Company Profile</div>
                      <div onClick={() => navigate("/JourneyAndGrowth")} className='head_main_links_cont_link'>Magnum Journey & Growth</div>
                      <div onClick={() => navigate("/Teams")} className='head_main_links_cont_link'>Magnum Team</div>
                  </div>
                  <div className='head_main_links_div'>Services</div>
                  <div className='head_main_links_cont'>
                      <div onClick={() => navigate("/BPO")} className='head_main_links_cont_link'>BPO</div>
                      <div onClick={() => navigate("/KPO")} className='head_main_links_cont_link'>KPO</div>
                      <div onClick={() => navigate("/OperationAndMain")} className='head_main_links_cont_link'>Operation & Maintanance</div>
                      <div onClick={() => navigate("/Utility")} className='head_main_links_cont_link'>Utility Management</div>
                      <div onClick={() => navigate("/Security")} className='head_main_links_cont_link'>Security Serivces</div>
                      <div onClick={() => navigate("/ITES")} className='head_main_links_cont_link'>ITES</div>
                  </div>
                  <div onClick={() => navigate("/Industry")} className='head_main_links_div'>Industries</div>
                  <div onClick={() => navigate("/NewsAndEvents")} className='head_main_links_div'>News & Events</div>
                  <div onClick={() => navigate("/Career")} className='head_main_links_div'>Career</div>
                  <div onClick={() => navigate("/Contact")} className='head_main_links_div'>Contact Us</div>
                  
                  
              </div>
              </>
          )}
      </div>

        <div className='banCont_2' data-aos="fade-up">
        <div className='banTitleCont_2'>
          <div className='banTitInner_2'>
          <div className='banTit1_2'>Revolutionizing Industries through Innovation</div>
          </div>
        </div>
        <img src={im31} className='banImg_2' alt="banner" />
      </div>

        
        {/* comp 8 */}
        <div className='c1_1' style={{paddingBottom: 0, marginTop: 20, marginBottom: 40}}  data-aos="fade-up">
            <div className='c8'>INDUSTRIES WHERE WE SERVE</div>
        </div>

        {/* comp 6 */}
        <div className='c6' data-aos="fade-up" id="num1">

            <div className='c61'>
                <div className='c611'><img className='c6111' src={im33} alt='as' /></div>
                <div className='c612'>
                    <div className='cp011'>TELECOM</div>
                    <div className='c33'><span></span> Customer Service</div>
                    <div className='c33'><span></span> Grievance Management And feedback</div>
                    <div className='c33'><span></span> End to End Customer Lifecycle Management</div>
                    <div className='c33'><span></span> Collection</div>
                    <div className='c33'><span></span> Customer Retention</div>
                    <div className='c33'><span></span> Upselling</div>
                    <div className='c33'><span></span> Sales and Promotion</div>
                </div>
                <div className='c6_n1'>
                    <img src={l11} alt='LOB' />
                    <img src={l12} alt='LOB' />
                    <img src={l13} alt='LOB' />
                    <img src={l14} alt='LOB' />
                </div>
                
                <div className='c6_n2 desktop'>
                    <div className='c6_n21' onMouseOver={() => setL1(true)} onMouseLeave={() => setL1(false)}>LOBs</div>
                    {l1 && (<>
                    
                        <div className='c6_n22'>
                            <div className='c6_n221'>&bull; Inbound</div>
                            <div className='c6_n221'>&bull; Outbound</div>
                            <div className='c6_n221'>&bull; Tele Verification</div>
                            <div className='c6_n221'>&bull; Dealer HelpDesk</div>
                            <div className='c6_n221'>&bull; Retentions</div>
                            <div className='c6_n221'>&bull; Collection</div>
                            <div className='c6_n221'>&bull; Chat</div>
                            <div className='c6_n221'>&bull; Email</div>
                        </div>
                    
                    </>)}
                    
                </div>
            </div>

            <div className='c61'>
                <div className='c611'><img className='c6111' src={im34} alt='as' /></div>
                <div className='c612'>
                    <div className='cp011'>E-COMMERCE</div>
                    <div className='c33'><span></span> Profile Management</div>
                    <div className='c33'><span></span> Products Listing</div>
                    <div className='c33'><span></span> Customer Expansion</div>
                    <div className='c33'><span></span> B2B,B2C & C2B Services</div>
                    <div className='c33'><span></span> Product Categorization</div>
                </div>
                <div className='c6_n1'>
                    <img src={l21} alt='LOB' />
                    <img src={l22} alt='LOB' />
                    <img src={l23} alt='LOB' />
                    <img src={l24} alt='LOB' />
                    <img src={l25} alt='LOB' />
                    <img src={l26} alt='LOB' />
                    <img src={l27} alt='LOB' />
                    <img src={l28} alt='LOB' />
                    <img src={l29} alt='LOB' />
                </div>
                
                <div className='c6_n2 desktop'>
                    <div className='c6_n21' onMouseOver={() => setL2(true)} onMouseLeave={() => setL2(false)}>LOBs</div>
                    
                    {l2 && (<>
                        <div className='c6_n22'>
                            <div className='c6_n221'>&bull; Inbound</div>
                            <div className='c6_n221'>&bull; Outbound</div>
                            <div className='c6_n221'>&bull; Chat</div>
                            <div className='c6_n221'>&bull; Web Chat</div>
                            <div className='c6_n221'>&bull; Email</div>
                            <div className='c6_n221'>&bull; Products Listing</div>
                            <div className='c6_n221'>&bull; Seller Onboarding</div>
                            <div className='c6_n221'>&bull; Taxonomy</div>
                        </div>
                    </>)}
                    
                </div>
            </div>

        </div>

        {/* comp 6 */}
        <div className='c6' data-aos="fade-up">

            <div className='c61'>
                <div className='c611'><img className='c6111' src={im35} alt='as' /></div>
                <div className='c612'>
                    <div className='cp011'>E-GOVERNANCE</div>
                    <div className='c33'><span></span> Grievance Management</div>
                    <div className='c33'><span></span> Perception Management</div>
                    <div className='c33'><span></span> Citizen Feedback System</div>
                    <div className='c33'><span></span> Analytics</div>
                    <div className='c33'><span></span> Satisfaction Index</div>
                    <div className='c33'><span></span> Skill Development Projects</div>
                </div>
                <div className='c6_n1'>
                    <img src={l31} alt='LOB' />
                    <img src={l32} alt='LOB' />
                    <img src={l33} alt='LOB' />
                    <img src={l34} alt='LOB' />
                    <img src={l35} alt='LOB' />
                    <img src={l36} alt='LOB' />
                    <img src={l37} alt='LOB' />
                    <img src={l38} alt='LOB' />
                    <img src={l39} alt='LOB' />
                    <img src={l310} alt='LOB' />
                    <img src={l311} alt='LOB' />
                    <img src={l312} alt='LOB' />
                </div>
                
                <div className='c6_n2 desktop'>
                    <div className='c6_n21' onMouseOver={() => setL3(true)} onMouseLeave={() => setL3(false)}>LOBs</div>
                    {l3 && (<>
                        <div className='c6_n22'>
                            <div className='c6_n221'>&bull; Inbound</div>
                            <div className='c6_n221'>&bull; Outbound</div>
                            <div className='c6_n221'>&bull; Tele Verification</div>
                        </div>
                    </>)}
                    
                </div>
            </div>

            <div className='c61'>
                <div className='c611'><img className='c6111' src={im36} alt='as' /></div>
                <div className='c612'>
                <div className='cp011'>DIGITAL TV & OTHERS</div>
                    <div className='c33'><span></span> Customer Services</div>
                    <div className='c33'><span></span> Customer Retention</div>
                    <div className='c33'><span></span> Upselling</div>
                    <div className='c33'><span></span> Grievance Management And Feedback Management</div>
                </div>
                <div className='c6_n1'>
                    <img src={l41} alt='LOB' />
                    <img src={l42} alt='LOB' />
                    <img src={l43} alt='LOB' />
                    <img src={l44} alt='LOB' />
                    <img src={l45} alt='LOB' />
                    <img src={l46} alt='LOB' />
                </div>
                
                <div className='c6_n2 desktop'>
                    <div className='c6_n21' onMouseOver={() => setL4(true)} onMouseLeave={() => setL4(false)}>LOBs</div>
                    {l4 && (<>
                        <div className='c6_n22'>
                            <div className='c6_n221'>&bull; Inbound</div>
                            <div className='c6_n221'>&bull; Outbound</div>
                            <div className='c6_n221'>&bull; Dealer HelpDesk</div>
                            <div className='c6_n221'>&bull; Tele-verification</div>
                        </div>
                    </>)}
                    
                </div>
            </div>

        </div>

        <div className='c6' data-aos="fade-up">

            <div className='c61'>
                <div className='c611'><img className='c6111' src={im37} alt='as' /></div>
                <div className='c612'>
                    <div className='cp011'>BFSI</div>
                    <div className='c33'><span></span> Merchant services (voucher processing, reconciliation & reporting collection)</div>
                    <div className='c33'><span></span> Investment Management</div>
                </div>
                <div className='c6_n1'>
                    <img src={l51} alt='LOB' />
                    <img src={l52} alt='LOB' />
                    <img src={l53} alt='LOB' />
                    <img src={l54} alt='LOB' />
                    <img src={l55} alt='LOB' />
                    <img src={l56} alt='LOB' />
                    <img src={l57} alt='LOB' />
                </div>
                
                <div className='c6_n2 desktop'>
                    <div className='c6_n21' onMouseOver={() => setL5(true)} onMouseLeave={() => setL5(false)}>LOBs</div>
                    {l5 && (<>
                        <div className='c6_n22'>
                            <div className='c6_n221'>&bull; Inbound</div>
                            <div className='c6_n221'>&bull; Outbound</div>
                            <div className='c6_n221'>&bull; Chat</div>
                            <div className='c6_n221'>&bull; Email</div>
                            <div className='c6_n221'>&bull; Collection</div>
                            <div className='c6_n221'>&bull; Disbursement</div>
                            <div className='c6_n221'>&bull; Document Verification</div>
                        </div>
                    </>)}
                    
                </div>
            </div>

        </div>

        <Footer />
        
    </>

  );
}

export default Industry;
