import logo from '../images/logo/MagnumLogo.png';
import menu from '../images/icons/menu.svg';
import close from '../images/icons/close.svg';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import im4 from '../images/img/4.png';
import im5 from '../images/img/5.png';
import im6 from '../images/img/6.png';
import im7 from '../images/img/7.png';
import im8 from '../images/img/8.png';

import Footer from '../components/Footer';

function Teams() {

  const navigate = useNavigate()
  const [menuBtn, setMenuBtn] = useState(false);
  const [menu1, setMenu1] = useState(false);
  const [menu2, setMenu2] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (

    <>
    
    <div className='head_main desktop'>
          <div className='head_logo'><img src={logo} alt="logo" /></div>
          <div className='head_main_links'>
              <div onClick={() => navigate("/")} className='head_main_links_div '>Home</div>
              <div className='head_main_links_div' onMouseOver={() => setMenu1(true)} onMouseLeave={() => setMenu1(false)}>About Us
              {menu1 && (
                  <div className='head_main_links_cont' onMouseOver={() => setMenu1(true)} onMouseLeave={() => setMenu1(false)}>
                      <div onClick={() => navigate("/CompanyProfile")} className='head_main_links_cont_link'>Company Profile</div>
                      <div onClick={() => navigate("/JourneyAndGrowth")} className='head_main_links_cont_link'>Magnum Journey & Growth</div>
                      <div onClick={() => navigate("/Teams")} className='head_main_links_cont_link activeLinkHead'>Magnum Team</div>
                  </div>
              )}
              </div>
              <div className='head_main_links_div' onMouseOver={() => setMenu2(true)} onMouseLeave={() => setMenu2(false)}>Services
              {menu2 && (
                <div className='head_main_links_cont' onMouseOver={() => setMenu2(true)} onMouseLeave={() => setMenu2(false)}>
                  <div onClick={() => navigate("/BPO")} className='head_main_links_cont_link'>BPO</div>
                  <div onClick={() => navigate("/KPO")} className='head_main_links_cont_link'>KPO</div>
                  <div onClick={() => navigate("/OperationAndMain")} className='head_main_links_cont_link'>Operation & Maintanance</div>
                  <div onClick={() => navigate("/Utility")} className='head_main_links_cont_link'>Utility Management</div>
                  <div onClick={() => navigate("/Security")} className='head_main_links_cont_link'>Security Serivces</div>
                  <div onClick={() => navigate("/ITES")} className='head_main_links_cont_link'>ITES</div>
                </div>
              )}
              </div>
              <div onClick={() => navigate("/Industry")} className='head_main_links_div'>Industries</div>
              <div onClick={() => navigate("/NewsAndEvents")} className='head_main_links_div'>News & Events</div>
              <div onClick={() => navigate("/Career")} className='head_main_links_div'>Career</div>
              <div onClick={() => navigate("/Contact")} className='head_main_links_div'>Contact Us</div>
          </div>
      </div>

      <div className='head_main mobile'>
          <div className='head_logo'><img src={logo} alt="logo" /></div>
          <div className='head_menu_btn' onClick={() => setMenuBtn(true)}><img src={menu} alt="menu" /></div>
          {menuBtn && (
              <>
              <div className='mobile_cont'><img src={close} alt='close' onClick={() => setMenuBtn(false)} /></div>
              <div className='head_mobile_link'>
                  <div onClick={() => navigate("/")} className='head_main_links_div activeLinkHead'>Home</div>
                  <div className='head_main_links_div'>About Us</div>
                  <div className='head_main_links_cont'>
                      <div onClick={() => navigate("/CompanyProfile")} className='head_main_links_cont_link'>Company Profile</div>
                      <div onClick={() => navigate("/JourneyAndGrowth")} className='head_main_links_cont_link'>Magnum Journey & Growth</div>
                      <div onClick={() => navigate("/Teams")} className='head_main_links_cont_link'>Magnum Team</div>
                  </div>
                  <div className='head_main_links_div'>Services</div>
                  <div className='head_main_links_cont'>
                      <div onClick={() => navigate("/BPO")} className='head_main_links_cont_link'>BPO</div>
                      <div onClick={() => navigate("/KPO")} className='head_main_links_cont_link'>KPO</div>
                      <div onClick={() => navigate("/OperationAndMain")} className='head_main_links_cont_link'>Operation & Maintanance</div>
                      <div onClick={() => navigate("/Utility")} className='head_main_links_cont_link'>Utility Management</div>
                      <div onClick={() => navigate("/Security")} className='head_main_links_cont_link'>Security Serivces</div>
                      <div onClick={() => navigate("/ITES")} className='head_main_links_cont_link'>ITES</div>
                  </div>
                  <div onClick={() => navigate("/Industry")} className='head_main_links_div'>Industries</div>
                  <div onClick={() => navigate("/NewsAndEvents")} className='head_main_links_div'>News & Events</div>
                  <div onClick={() => navigate("/Career")} className='head_main_links_div'>Career</div>
                  <div onClick={() => navigate("/Contact")} className='head_main_links_div'>Contact Us</div>
                  
                  
              </div>
              </>
          )}
      </div>

      {/* /banner */}
      <div className='banCont_2' data-aos="fade-up">
        <div className='banTitleCont_2'>
          <div className='banTitInner_2'>
            <div className='banTit1_2'>Innovative, <br/>Minds, <br />Unified Goals.</div>
            <div className='banTit2_2'>One Team, One Mission</div>
          </div>
        </div>
        <img src={im4} className='banImg_2' alt="banner" />
      </div>

      {/* Industry Experience */}
      <div className='c1_1' data-aos="fade-up">
        <div className='c1_11'>Our Management Team</div>
        <div className='c1_12'>Dedicated, Determined, and Unstoppable</div>
        <div className='c1_13'>
            <div className='t1'>
                <div className='t11'>
                    <div className='t111'><img src={im5} /></div>
                    <div className='t112'>
                        <div className='t1121'>Capt. Prithvi Raj Rishi</div>
                        <div className='t1122'>Managing Director</div>
                        <div className='t1123'>A Master Mariner with 16 Yrs experience 
in Merchant Navy & 30+ Yrs experience in 
Business Operations, he is responsible 
for managing Magnum’s growth. His vision as the founder has steered the company toward achieving leadership position. He has been instrumental in shaping Magnum's focus strategy & building a unique partnership model based on the tenets of innovation & sustainable development.</div>
                    </div>
                </div>
                <div className='t11'>
                    <div className='t111'><img src={im6} /></div>
                    <div className='t112'>
                        <div className='t1121'>Vijitraj Rishi</div>
                        <div className='t1122'>Director</div>
                        <div className='t1123'>Vijitraj Rishi has been associated with Magnum 
since 2013 and he brings along altogether a new 
vision to the organisation with his positive energy, disciplined approach and great leadership quality.He has a strong work ethic & ability to respect & deal with the inevitable issues. His ability to adapt to scenarios that call for cross-functional skills is admirable, in addition to his bigger vision that goes into making a company thrive. With his support, company has reached new heights.

He has done his schooling from Daly College, Indore and was a Head Boy of his school. He did his Graduation from HR College Mumbai and Post Graduation in Mass Communication from KC College, Mumbai. He is an intense Sports Person and was a National Level player and Captain in Squash. He has represented Dally college for National Level in Football & Shooting.</div>
                    </div>
                </div>

                <div className='t11'>
                    <div className='t111'><img src={im7} /></div>
                    <div className='t112'>
                        <div className='t1121'>MANISH DUBEY</div>
                        <div className='t1122'>CEO</div>
                        <div className='t1123'>He has 28+ years of experince in the areas of Business Operations, Vendor Management, Cost Optimization, Strategic Planning & Customer Relationship Management. His experience is passing on lots of value to all our existing and upcoming clients through in day to day opeartions.</div>
                    </div>
                </div>
                <div className='t11'>
                    <div className='t111'><img src={im8} /></div>
                    <div className='t112'>
                        <div className='t1121'>NAUSHAD ALAM</div>
                        <div className='t1122'>VP - OPERATIONS</div>
                        <div className='t1123'>Naushad VP -Operations at Magnum Group. 
He has 18+ years of experience in the areas 
of operations management, process 
management, team management, VAS sales 
& outsourcing. He was instrumental in incubating various practice that is helping this organisation achieve it’s goal.</div>
                    </div>
                </div>

            </div>
        </div>

      </div>

      <Footer />
    
    </>

  );
}

export default Teams;
