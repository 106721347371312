/* eslint-disable jsx-a11y/img-redundant-alt */
import logo from '../images/logo/MagnumLogo.png';
import menu from '../images/icons/menu.svg';
import close from '../images/icons/close.svg';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Footer from '../components/Footer';
import im30 from '../images/img/30.png';

import im45 from '../images/img/45.png';
import im46 from '../images/img/46.png';
import im47 from '../images/img/47.png';
import im48 from '../images/img/48.png';
import im49 from '../images/img/49.png';
import im50 from '../images/img/50.png';
import im52 from '../images/img/52.png';
import im54 from '../images/img/54.png';
import im55 from '../images/img/55.png';
import im56 from '../images/img/56.png';

import crm1 from '../images/crm/CMHL_CRM.jpg';
import crm2 from '../images/crm/Jabalpur_CRM.jpg';

function ITES() {

    const navigate = useNavigate()
    const [menuBtn, setMenuBtn] = useState(false);
    const [menu1, setMenu1] = useState(false);
    const [menu2, setMenu2] = useState(false);

    const [popup, setPopup] = useState(false);
    const [imgPlace, setImgPlace] = useState(null);
    const openPopup = ind => {
        if (ind === 1) {
            setImgPlace(crm1);
        } else {
            setImgPlace(crm2);
        }
        setPopup(true);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (

    <>
    
    <div className='head_main desktop'>
          <div className='head_logo'><img src={logo} alt="logo" /></div>
          <div className='head_main_links'>
              <div onClick={() => navigate("/")} className='head_main_links_div '>Home</div>
              <div className='head_main_links_div' onMouseOver={() => setMenu1(true)} onMouseLeave={() => setMenu1(false)}>About Us
              {menu1 && (
                  <div className='head_main_links_cont' onMouseOver={() => setMenu1(true)} onMouseLeave={() => setMenu1(false)}>
                      <div onClick={() => navigate("/CompanyProfile")} className='head_main_links_cont_link'>Company Profile</div>
                      <div onClick={() => navigate("/JourneyAndGrowth")} className='head_main_links_cont_link'>Magnum Journey & Growth</div>
                      <div onClick={() => navigate("/Teams")} className='head_main_links_cont_link'>Magnum Team</div>
                  </div>
              )}
              </div>
              <div className='head_main_links_div' onMouseOver={() => setMenu2(true)} onMouseLeave={() => setMenu2(false)}>Services
              {menu2 && (
                <div className='head_main_links_cont' onMouseOver={() => setMenu2(true)} onMouseLeave={() => setMenu2(false)}>
                  <div onClick={() => navigate("/BPO")} className='head_main_links_cont_link'>BPO</div>
                  <div onClick={() => navigate("/KPO")} className='head_main_links_cont_link'>KPO</div>
                  <div onClick={() => navigate("/OperationAndMain")} className='head_main_links_cont_link'>Operation & Maintanance</div>
                  <div onClick={() => navigate("/Utility")} className='head_main_links_cont_link'>Utility Management</div>
                  <div onClick={() => navigate("/Security")} className='head_main_links_cont_link'>Security Serivces</div>
                  <div onClick={() => navigate("/ITES")} className='head_main_links_cont_link  activeLinkHead'>ITES</div>
                </div>
              )}
              </div>
              <div onClick={() => navigate("/Industry")} className='head_main_links_div'>Industries</div>
              <div onClick={() => navigate("/NewsAndEvents")} className='head_main_links_div'>News & Events</div>
              <div onClick={() => navigate("/Career")} className='head_main_links_div'>Career</div>
              <div onClick={() => navigate("/Contact")} className='head_main_links_div'>Contact Us</div>
          </div>
      </div>

      <div className='head_main mobile'>
          <div className='head_logo'><img src={logo} alt="logo" /></div>
          <div className='head_menu_btn' onClick={() => setMenuBtn(true)}><img src={menu} alt="menu" /></div>
          {menuBtn && (
              <>
              <div className='mobile_cont'><img src={close} alt='close' onClick={() => setMenuBtn(false)} /></div>
              <div className='head_mobile_link'>
                  <div onClick={() => navigate("/")} className='head_main_links_div activeLinkHead'>Home</div>
                  <div className='head_main_links_div'>About Us</div>
                  <div className='head_main_links_cont'>
                      <div onClick={() => navigate("/CompanyProfile")} className='head_main_links_cont_link'>Company Profile</div>
                      <div onClick={() => navigate("/JourneyAndGrowth")} className='head_main_links_cont_link'>Magnum Journey & Growth</div>
                      <div onClick={() => navigate("/Teams")} className='head_main_links_cont_link'>Magnum Team</div>
                  </div>
                  <div className='head_main_links_div'>Services</div>
                  <div className='head_main_links_cont'>
                      <div onClick={() => navigate("/BPO")} className='head_main_links_cont_link'>BPO</div>
                      <div onClick={() => navigate("/KPO")} className='head_main_links_cont_link'>KPO</div>
                      <div onClick={() => navigate("/OperationAndMain")} className='head_main_links_cont_link'>Operation & Maintanance</div>
                      <div onClick={() => navigate("/Utility")} className='head_main_links_cont_link'>Utility Management</div>
                      <div onClick={() => navigate("/Security")} className='head_main_links_cont_link'>Security Serivces</div>
                      <div onClick={() => navigate("/ITES")} className='head_main_links_cont_link'>ITES</div>
                  </div>
                  <div onClick={() => navigate("/Industry")} className='head_main_links_div'>Industries</div>
                  <div onClick={() => navigate("/NewsAndEvents")} className='head_main_links_div'>News & Events</div>
                  <div onClick={() => navigate("/Career")} className='head_main_links_div'>Career</div>
                  <div onClick={() => navigate("/Contact")} className='head_main_links_div'>Contact Us</div>
                  
                  
              </div>
              </>
            )}
        </div>

        <div className='banCont_2'  data-aos="fade-up">
            <div className='banTitleCont_2'>
                <div className='banTitInner_2'>
                    <div className='banTit1_2'>Trusted ITES Solutions</div>
                    <div className='banTit2_2'>Navigating the Digital Landscape</div>
                </div>
            </div>
            <img src={im30} className='banImg_2' alt="banner" />
        </div>

        {/* newBox */}
        <div className='n1_cont'>
            <div className='n1_content'>
                The CRM platform we have developed offers an extensive array of over 1200+ complaint registration attributes, catering to the unique demands of 56+ departments within the Government of Madhya Pradesh.
                <div className='n1_float_btn' onClick={() => {openPopup(1)}}>CM HELPLINE CRM</div>
            </div>
            <div className='n1_content'>
                Our in-house ITES team has skilfully designed and developed the MD Dashboard, an all-inclusive homepage for Madhya Pradesh Poorv Kshetra Vidyut Vitaran Company Ltd. This comprehensive dashboard is equipped with a wide range of analytical features, allowing users to effortlessly track total complaints, monitor open complaints, perform category-wise analysis, generate report cards, and more. Developed applications when required to make it user-friendly and easier for the consumer to lodge and resolve their grievances.
                <div className='n1_float_btn' onClick={() => {openPopup(2)}}>MPPKVVCL CRM</div>
            </div>
        </div>
        
        {/* comp 8 */}
        <div className='c1_1' style={{paddingBottom: 0, marginTop: 20, marginBottom: 40}} data-aos="fade-up">
            <div className='c8'>GETTING TO KNOW ITES</div>
            <div className='c22'>Information Technology Enabled Services. This is also fall within IT but majorly focused on service delivery. e.g. service desk analyst, production support analyst, IT analyst. Magnum ITES offer platforms for corporate functions like Human Resources, Finance and Accounting and Procurement. Our team working on platforms like VICIdial, C-Zentrix, Agami Tech (Intalk.io), Avaya, Ameyo, Genesys, Convox, Nortel etc.</div>
        </div>

        {/* comp 6 */}
        <div className='c6' data-aos="fade-up">

            <div className='c61'>
                <div className='c611'><img className='c6111' src={im46} alt='as' /></div>
                <div className='c612'>
                    <div className='cp011'>Product Engineering Services</div>
                    <div className='c33'><span></span> Product Development & Modernization</div>
                    <div className='c33'><span></span> Quality Testing</div>
                    <div className='c33'><span></span> ERP Development</div>
                    <div className='c33'><span></span> Product Architecture</div>
                    <div className='c33'><span></span> Product Migration and Porting</div>
                </div>
            </div>

            <div className='c61 desktop'>
                <div className='c612'>
                    <div className='cp011'>Web Application Services</div>
                    <div className='c33'><span></span> Customization</div>
                    <div className='c33'><span></span> Integration</div>
                    <div className='c33'><span></span> Development & Deployment</div>
                    <div className='c33'><span></span> Support & Maintenance</div>
                    <div className='c33'><span></span> Content Management</div>
                </div>
                <div className='c611'><img className='c6111' src={im56} alt='as' /></div>
            </div>
            <div className='c61 mobile'>
                <div className='c611'><img className='c6111' src={im56} alt='as' /></div>
                <div className='c612'>
                    <div className='cp011'>Web Application Services</div>
                    <div className='c33'><span></span> Customization</div>
                    <div className='c33'><span></span> Integration</div>
                    <div className='c33'><span></span> Development & Deployment</div>
                    <div className='c33'><span></span> Support & Maintenance</div>
                    <div className='c33'><span></span> Content Management</div>
                </div>
            </div>

        </div>

        {/* comp 6 */}
        <div className='c6' data-aos="fade-up">

            <div className='c61'>
                <div className='c611'><img className='c6111' src={im54} alt='as' /></div>
                <div className='c612'>
                    <div className='cp011'>Mobility Services</div>
                    <div className='c33'><span></span> Android/IOS App Development</div>
                    <div className='c33'><span></span> Multi Social Platform Integration</div>
                    <div className='c33'><span></span> Managed Mobility Services</div>
                    <div className='c33'><span></span> Automation Services</div>
                    <div className='c33'><span></span> Mobility Consulting</div>
                    <div className='c33'><span></span> Android/iOS App Development</div>
                    <div className='c33'><span></span> Multi Social Platform Integration</div>
                </div>
            </div>

            <div className='c61 desktop'>
                <div className='c612'>
                    <div className='cp011'>Maintenance and Support</div>
                    <div className='c33'><span></span> Greater efficiency through industrialized service</div>
                    <div className='c33'><span></span> Enhanced value realization with business and IT</div>
                    <div className='c33'><span></span> Transformation inflight</div>
                    <div className='c33'><span></span> Project Management</div>
                    <div className='c33'><span></span> Project Consultancy Service</div>
                </div>
                <div className='c611'><img className='c6111' src={im48} alt='as' /></div>
            </div>
            <div className='c61 mobile'>
                <div className='c611'><img className='c6111' src={im48} alt='as' /></div>
                <div className='c612'>
                    <div className='cp011'>Maintenance and Support</div>
                    <div className='c33'><span></span> Greater efficiency through industrialized service</div>
                    <div className='c33'><span></span> Enhanced value realization with business and IT</div>
                    <div className='c33'><span></span> Transformation inflight</div>
                    <div className='c33'><span></span> Project Management</div>
                    <div className='c33'><span></span> Project Consultancy Service</div>
                </div>
            </div>

        </div>

        {/* comp 6 */}
        <div className='c6' data-aos="fade-up">

            <div className='c61'>
                <div className='c611'><img className='c6111' src={im46} alt='as' /></div>
                <div className='c612'>
                    <div className='cp011'>Business Technology Services</div>
                    <div className='c33'><span></span> Custom Application Development</div>
                    <div className='c33'><span></span> App Maintenance</div>
                    <div className='c33'><span></span> R&D</div>
                </div>
            </div>

            <div className='c61 desktop'>
                <div className='c612'>
                    <div className='cp011'>E-Commerce Development</div>
                    <div className='c33'><span></span> Online shopping web sites</div>
                    <div className='c33'><span></span> Online marketplaces</div>
                    <div className='c33'><span></span> Product Management</div>
                </div>
                <div className='c611'><img className='c6111' src={im52} alt='as' /></div>
            </div>
            <div className='c61 mobile'>
                <div className='c611'><img className='c6111' src={im52} alt='as' /></div>
                <div className='c612'>
                    <div className='cp011'>E-Commerce Development</div>
                    <div className='c33'><span></span> Online shopping web sites</div>
                    <div className='c33'><span></span> Online marketplaces</div>
                    <div className='c33'><span></span> Product Management</div>
                </div>
            </div>

        </div>

        {/* comp 8 */}
        <div className='c1_1' style={{paddingBottom: 0, marginTop: 20, marginBottom: 40}} data-aos="fade-up">
            <div className='c8'>TECHNOLOGIES WORK FOR</div>
        </div>

        {/* comp 6 */}
        <div className='c6' data-aos="fade-up">

            <div className='c61'>
                <div className='c611'><img className='c6111' src={im50} alt='as' /></div>
                <div className='c612'>
                    <div className='cp011'>Server Side Technology</div>
                    <div className='c33'><span></span> Core PHP</div>
                    <div className='c33'><span></span> Node Js</div>
                </div>
            </div>

            <div className='c61 desktop'>
                <div className='c612'>
                    <div className='cp011'>Frameworks</div>
                    <div className='c33'><span></span> CodeIgniter</div>
                    <div className='c33'><span></span> Laravel</div>
                    <div className='c33'><span></span> Zend Framework</div>
                    <div className='c33'><span></span> Yii</div>
                </div>
                <div className='c611'><img className='c6111' src={im49} alt='as' /></div>
            </div>
            <div className='c61 mobile'>
                <div className='c611'><img className='c6111' src={im49} alt='as' /></div>
                <div className='c612'>
                    <div className='cp011'>Frameworks</div>
                    <div className='c33'><span></span> CodeIgniter</div>
                    <div className='c33'><span></span> Laravel</div>
                    <div className='c33'><span></span> Zend Framework</div>
                    <div className='c33'><span></span> Yii</div>
                </div>
            </div>

        </div>

        {/* comp 6 */}
        <div className='c6' data-aos="fade-up">

            <div className='c61'>
                <div className='c611'><img className='c6111' src={im48} alt='as' /></div>
                <div className='c612'>
                    <div className='cp011'>Client Side Technology</div>
                    <div className='c33'><span></span> JQuery</div>
                    <div className='c33'><span></span> Angular JS</div>
                    <div className='c33'><span></span> JQuery Mobile</div>
                </div>
            </div>

            <div className='c61 desktop'>
                <div className='c612'>
                    <div className='cp011'>E-Commerce</div>
                    <div className='c33'><span></span> Magento</div>
                    <div className='c33'><span></span> Zen Cart</div>
                    <div className='c33'><span></span> OpenCart</div>
                    <div className='c33'><span></span> osCommerce</div>
                    <div className='c33'><span></span> PrestaShop</div>
                </div>
                <div className='c611'><img className='c6111' src={im47} alt='as' /></div>
            </div>
            <div className='c61 mobile'>
                <div className='c611'><img className='c6111' src={im47} alt='as' /></div>
                <div className='c612'>
                    <div className='cp011'>E-Commerce</div>
                    <div className='c33'><span></span> Magento</div>
                    <div className='c33'><span></span> Zen Cart</div>
                    <div className='c33'><span></span> OpenCart</div>
                    <div className='c33'><span></span> osCommerce</div>
                    <div className='c33'><span></span> PrestaShop</div>
                </div>
            </div>

        </div>

        {/* comp 6 */}
        <div className='c6' data-aos="fade-up">

            <div className='c61'>
                <div className='c611'><img className='c6111' src={im55} alt='as' /></div>
                <div className='c612'>
                    <div className='cp011'>E-Learning</div>
                    <div className='c33'><span></span> Moodle</div>
                    <div className='c33'><span></span> eFront</div>
                </div>
            </div>

            <div className='c61 desktop'>
                <div className='c612'>
                    <div className='cp011'>CMS</div>
                    <div className='c33'><span></span> Wordpress</div>
                    <div className='c33'><span></span> Drupal</div>
                </div>
                <div className='c611'><img className='c6111' src={im45} alt='as' /></div>
            </div>
            <div className='c61 mobile'>
                <div className='c611'><img className='c6111' src={im45} alt='as' /></div>
                <div className='c612'>
                    <div className='cp011'>CMS</div>
                    <div className='c33'><span></span> Wordpress</div>
                    <div className='c33'><span></span> Drupal</div>
                </div>
            </div>

        </div>

        <Footer />

        {/* popup */}
        {popup && (
            <div className='n2_cont' onClick={() => {setPopup(false)}}>
                <img src={imgPlace} alt="crm image" />
            </div>
        )}

        
    </>

  );
}

export default ITES;
