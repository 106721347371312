import logo from '../images/logo/MagnumLogo.png'
import menu from '../images/icons/menu.svg'
import close from '../images/icons/close.svg'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import Footer from '../components/Footer'

import im43 from '../images/img/43.png'
import im44 from '../images/img/44.png'

import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'

import { feedbackFormSchema } from '../schema'
import { useFormik } from 'formik'

function Career() {
  const navigate = useNavigate()
  const [menuBtn, setMenuBtn] = useState(false)
  const [menu1, setMenu1] = useState(false)
  const [menu2, setMenu2] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    resetForm,
  } = useFormik({
    initialValues: {
      name: '',
      email: '',
      contact: '',
      text: '',
    },
    validationSchema: feedbackFormSchema,
    onSubmit: (values) => {
      handleAPI(values)
    },
  })

  const handleAPI = (values) => {
    var formdata = new FormData()
    formdata.append('full_name', values.name)
    formdata.append('email_id', values.email)
    formdata.append('message', values.text)
    formdata.append('contact_no', values.contact)
    formdata.append('submit_btn', 'true')

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    }

    fetch('https://magnumgroup.co.in/contact1_us.php', requestOptions)
      .then((response) => response.text())
      .then(() => {
        // console.log(result)
        alert('Our team will reach you out.')
        resetForm()
      })
      .catch((error) => console.log('error', error))
  }

  return (
    <>
      <div className="head_main desktop">
        <div className="head_logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="head_main_links">
          <div onClick={() => navigate('/')} className="head_main_links_div ">
            Home
          </div>
          <div
            className="head_main_links_div"
            onMouseOver={() => setMenu1(true)}
            onMouseLeave={() => setMenu1(false)}
          >
            About Us
            {menu1 && (
              <div
                className="head_main_links_cont"
                onMouseOver={() => setMenu1(true)}
                onMouseLeave={() => setMenu1(false)}
              >
                <div
                  onClick={() => navigate('/CompanyProfile')}
                  className="head_main_links_cont_link"
                >
                  Company Profile
                </div>
                <div
                  onClick={() => navigate('/JourneyAndGrowth')}
                  className="head_main_links_cont_link"
                >
                  Magnum Journey & Growth
                </div>
                <div
                  onClick={() => navigate('/Teams')}
                  className="head_main_links_cont_link"
                >
                  Magnum Team
                </div>
              </div>
            )}
          </div>
          <div
            className="head_main_links_div"
            onMouseOver={() => setMenu2(true)}
            onMouseLeave={() => setMenu2(false)}
          >
            Services
            {menu2 && (
              <div
                className="head_main_links_cont"
                onMouseOver={() => setMenu2(true)}
                onMouseLeave={() => setMenu2(false)}
              >
                <div
                  onClick={() => navigate('/BPO')}
                  className="head_main_links_cont_link"
                >
                  BPO
                </div>
                <div
                  onClick={() => navigate('/KPO')}
                  className="head_main_links_cont_link"
                >
                  KPO
                </div>
                <div
                  onClick={() => navigate('/OperationAndMain')}
                  className="head_main_links_cont_link"
                >
                  Operation & Maintanance
                </div>
                <div
                  onClick={() => navigate('/Utility')}
                  className="head_main_links_cont_link"
                >
                  Utility Management
                </div>
                <div
                  onClick={() => navigate('/Security')}
                  className="head_main_links_cont_link"
                >
                  Security Serivces
                </div>
                <div
                  onClick={() => navigate('/ITES')}
                  className="head_main_links_cont_link"
                >
                  ITES
                </div>
              </div>
            )}
          </div>
          <div
            onClick={() => navigate('/Industry')}
            className="head_main_links_div"
          >
            Industries
          </div>
          <div
            onClick={() => navigate('/NewsAndEvents')}
            className="head_main_links_div"
          >
            News & Events
          </div>
          <div
            onClick={() => navigate('/Career')}
            className="head_main_links_div"
          >
            Career
          </div>
          <div
            onClick={() => navigate('/Contact')}
            className="head_main_links_div activeLinkHead"
          >
            Contact Us
          </div>
        </div>
      </div>

      <div className="head_main mobile">
        <div className="head_logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="head_menu_btn" onClick={() => setMenuBtn(true)}>
          <img src={menu} alt="menu" />
        </div>
        {menuBtn && (
          <>
            <div className="mobile_cont">
              <img src={close} alt="close" onClick={() => setMenuBtn(false)} />
            </div>
            <div className="head_mobile_link">
              <div
                onClick={() => navigate('/')}
                className="head_main_links_div activeLinkHead"
              >
                Home
              </div>
              <div className="head_main_links_div">About Us</div>
              <div className="head_main_links_cont">
                <div
                  onClick={() => navigate('/CompanyProfile')}
                  className="head_main_links_cont_link"
                >
                  Company Profile
                </div>
                <div
                  onClick={() => navigate('/JourneyAndGrowth')}
                  className="head_main_links_cont_link"
                >
                  Magnum Journey & Growth
                </div>
                <div
                  onClick={() => navigate('/Teams')}
                  className="head_main_links_cont_link"
                >
                  Magnum Team
                </div>
              </div>
              <div className="head_main_links_div">Services</div>
              <div className="head_main_links_cont">
                <div
                  onClick={() => navigate('/BPO')}
                  className="head_main_links_cont_link"
                >
                  BPO
                </div>
                <div
                  onClick={() => navigate('/KPO')}
                  className="head_main_links_cont_link"
                >
                  KPO
                </div>
                <div
                  onClick={() => navigate('/OperationAndMain')}
                  className="head_main_links_cont_link"
                >
                  Operation & Maintanance
                </div>
                <div
                  onClick={() => navigate('/Utility')}
                  className="head_main_links_cont_link"
                >
                  Utility Management
                </div>
                <div
                  onClick={() => navigate('/Security')}
                  className="head_main_links_cont_link"
                >
                  Security Serivces
                </div>
                <div
                  onClick={() => navigate('/ITES')}
                  className="head_main_links_cont_link"
                >
                  ITES
                </div>
              </div>
              <div
                onClick={() => navigate('/Industry')}
                className="head_main_links_div"
              >
                Industries
              </div>
              <div
                onClick={() => navigate('/NewsAndEvents')}
                className="head_main_links_div"
              >
                News & Events
              </div>
              <div
                onClick={() => navigate('/Career')}
                className="head_main_links_div"
              >
                Career
              </div>
              <div
                onClick={() => navigate('/Contact')}
                className="head_main_links_div"
              >
                Contact Us
              </div>
            </div>
          </>
        )}
      </div>

      {/* /banner */}
      <div className="banCont_2" data-aos="fade-up">
        <div className="banTitleCont_2">
          <div className="banTitInner_2">
            <div className="banTit1_2">
              Get
              <br /> Connected
            </div>
            <div className="banTit2_2">Lets Make Things Happens</div>
          </div>
        </div>
        <img src={im43} className="banImg_2" alt="banner" />
      </div>

      {/* comp 4 */}
      <div className="c1_1" data-aos="fade-up">
        <div className="cp_0">
          <div className="cp0 desktop" data-aos="fade-up">
            <div className="cp01">
              <div className="c8_an" style={{ marginBottom: 20 }}>
                Contact details
              </div>
              <div className="c31_an">Head Office</div>
              <div className="cp012" style={{ marginBottom: 20 }}>
                Kalaniketan - I, Vaishali Nagar, Kotra Sultanabad, Bhopal -
                462003 (M.P.) India
              </div>

              <div className="c31_an">Corporate Office</div>
              <div className="cp012" style={{ marginBottom: 20 }}>
                MSD (I) Pvt. Ltd, Fourth Floor, Chinar Incube Business Center,
                Chinar Fortune City, Hoshangabad Road, Bhopal - 462026 (M.P.)
                India
                <br />
                ---
                <br />
                Anchor 3, 1st Floor, Aashima Mall, Hoshangabad Road, Bhopal
              </div>

              <div className="c31_an">For HR/Recruitment</div>

              <div className="c9">
                <div className="c91">
                  <AccountCircleIcon />
                </div>
                <div className="c92">
                  <div className="c921">Name</div>
                  <div className="c922">Rinki Baghele</div>
                </div>
              </div>

              <div className="c9">
                <div className="c91">
                  <PhoneIcon />
                </div>
                <div className="c92">
                  <div className="c921">Phone</div>
                  <div className="c922">+91 7898922333/ +91 8788044418</div>
                </div>
              </div>

              <div className="c9">
                <div className="c91">
                  <EmailIcon />
                </div>
                <div className="c92">
                  <div className="c921">Email</div>
                  <div className="c922">rinki.baghele@magnumgroup.co.in</div>
                </div>
              </div>
            </div>
            <div className="cp01">
              <img className="cp021" src={im44} alt="as" />
            </div>
          </div>

          <div
            className="cp0 desktop"
            style={{ marginTop: 60 }}
            data-aos="fade-up"
          >
            <div className="c31_an">For Business Enquiry</div>

            <div className="cp01">
              <div className="c9">
                <div className="c91">
                  <AccountCircleIcon />
                </div>
                <div className="c92">
                  <div className="c921">Name</div>
                  <div className="c922">Naushad Alam</div>
                </div>
              </div>
              <div className="c9">
                <div className="c91">
                  <PhoneIcon />
                </div>
                <div className="c92">
                  <div className="c921">Phone</div>
                  <div className="c922">+91 9098332211</div>
                </div>
              </div>
              <div className="c9">
                <div className="c91">
                  <EmailIcon />
                </div>
                <div className="c92">
                  <div className="c921">Email</div>
                  <div className="c922">naushad.alam@magnumgroup.co.in</div>
                </div>
              </div>
            </div>

            <div className="cp01">
              <div className="c9">
                <div className="c91">
                  <AccountCircleIcon />
                </div>
                <div className="c92">
                  <div className="c921">Name</div>
                  <div className="c922">Manish Dubey</div>
                </div>
              </div>
              <div className="c9">
                <div className="c91">
                  <PhoneIcon />
                </div>
                <div className="c92">
                  <div className="c921">Phone</div>
                  <div className="c922">+91 9685133533</div>
                </div>
              </div>
              <div className="c9">
                <div className="c91">
                  <EmailIcon />
                </div>
                <div className="c92">
                  <div className="c921">Email</div>
                  <div className="c922">manish.dubey@magnumgroup.co.in</div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="cp0 desktop"
            style={{ marginTop: 60 }}
            data-aos="fade-up"
          >
            <div className="c31_an">For Grievances (GRO/Nodal Officer)</div>
            <div className="cp01">
              <div className="c9">
                <div className="c91">
                  <AccountCircleIcon />
                </div>
                <div className="c92">
                  <div className="c921">Name</div>
                  <div className="c922">Praveen Birthare</div>
                </div>
              </div>
              <div className="c9">
                <div className="c91">
                  <PhoneIcon />
                </div>
                <div className="c92">
                  <div className="c921">Phone</div>
                  <div className="c922">+91 9229156165</div>
                </div>
              </div>
              <div className="c9">
                <div className="c91">
                  <EmailIcon />
                </div>
                <div className="c92">
                  <div className="c921">Email</div>
                  <div className="c922">praveen.kumar@magnumgroup.co.in</div>
                </div>
              </div>
            </div>
          </div>

          <div className="cp0 mobile" data-aos="fade-up">
            <div className="cp01">
              <img className="cp021" src={im44} alt="as" />
            </div>
            <div className="cp01">
              <div className="c8" style={{ marginBottom: 20 }}>
                Contact details
              </div>
              <div className="c31">Head Office</div>
              <div className="cp012" style={{ marginBottom: 20 }}>
                Kalaniketan - I, Vaishali Nagar, Kotra Sultanabad, Bhopal -
                462003 (M.P.) India
              </div>

              <div className="c31">Corporate Office</div>
              <div className="cp012" style={{ marginBottom: 20 }}>
                MSD (I) Pvt. Ltd, Fourth Floor, Chinar Incube Business Center,
                Chinar Fortune City, Hoshangabad Road, Bhopal - 462026 (M.P.)
                India
                <br />
                ---
                <br />
                Anchor 3, 1st Floor, Aashima Mall, Hoshangabad Road, Bhopal
              </div>

              <div className="c31">For HR/Recruitment</div>

              <div className="c9">
                <div className="c91">
                  <AccountCircleIcon />
                </div>
                <div className="c92">
                  <div className="c921">Name</div>
                  <div className="c922">Rinki Baghele</div>
                </div>
              </div>
              <div className="c9">
                <div className="c91">
                  <PhoneIcon />
                </div>
                <div className="c92">
                  <div className="c921">Phone</div>
                  <div className="c922">+91 7898922333/ +91 8788044418</div>
                </div>
              </div>
              <div className="c9">
                <div className="c91">
                  <EmailIcon />
                </div>
                <div className="c92">
                  <div className="c921">Email</div>
                  <div className="c922">rinki.baghele@magnumgroup.co.in</div>
                </div>
              </div>
            </div>
          </div>

          <div className="cp0 mobile" data-aos="fade-up">
            <div className="cp01">
              <div className="c31">For Business Enquiry</div>

              <div className="c9">
                <div className="c91">
                  <AccountCircleIcon />
                </div>
                <div className="c92">
                  <div className="c921">Name</div>
                  <div className="c922">Naushad Alam</div>
                </div>
              </div>
              <div className="c9">
                <div className="c91">
                  <PhoneIcon />
                </div>
                <div className="c92">
                  <div className="c921">Phone</div>
                  <div className="c922">+91 9098332211</div>
                </div>
              </div>
              <div className="c9">
                <div className="c91">
                  <EmailIcon />
                </div>
                <div className="c92">
                  <div className="c921">Email</div>
                  <div className="c922">naushad.alam@magnumgroup.co.in</div>
                </div>
              </div>
            </div>
            <div className="cp01">
              <div className="c9">
                <div className="c91">
                  <AccountCircleIcon />
                </div>
                <div className="c92">
                  <div className="c921">Name</div>
                  <div className="c922">Manish Dubey</div>
                </div>
              </div>
              <div className="c9">
                <div className="c91">
                  <PhoneIcon />
                </div>
                <div className="c92">
                  <div className="c921">Phone</div>
                  <div className="c922">+91 9685133533</div>
                </div>
              </div>
              <div className="c9">
                <div className="c91">
                  <EmailIcon />
                </div>
                <div className="c92">
                  <div className="c921">Email</div>
                  <div className="c922">manish.dubey@magnumgroup.co.in</div>
                </div>
              </div>
            </div>
          </div>

          <div className="cp0 mobile" data-aos="fade-up">
            <div className="cp01">
              <div className="c31">For Grievances (GRO/Nodal Officer)</div>

              <div className="c9">
                <div className="c91">
                  <AccountCircleIcon />
                </div>
                <div className="c92">
                  <div className="c921">Name</div>
                  <div className="c922">Praveen Birthare</div>
                </div>
              </div>
              <div className="c9">
                <div className="c91">
                  <PhoneIcon />
                </div>
                <div className="c92">
                  <div className="c921">Phone</div>
                  <div className="c922">+91 9229156165</div>
                </div>
              </div>
              <div className="c9">
                <div className="c91">
                  <EmailIcon />
                </div>
                <div className="c92">
                  <div className="c921">Email</div>
                  <div className="c922">praveen.kumar@magnumgroup.co.in</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* comp 8 */}
      <div
        className="c1_1"
        style={{ paddingBottom: 0, marginTop: -40 }}
        data-aos="fade-up"
      >
        <div className="c8">Business Enquiry</div>
      </div>

      {/* comp 7 */}
      <div className="c1_1" data-aos="fade-up">
        <div className="c7_cont contact" style={{ marginTop: -80 }}>
          <div className="c7">
            <div className="c71">Full Name</div>
            <input
              className="c72"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              placeholder="Enter here..."
            />
            {errors.name && touched.name ? (
              <div className="contact-error">{errors.name}</div>
            ) : null}
          </div>

          <div className="c7">
            <div className="c71">Email Address</div>
            <input
              className="c72"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              placeholder="Enter here..."
            />
            {errors.email && touched.email ? (
              <div className="contact-error">{errors.email}</div>
            ) : null}
          </div>

          <div className="c7">
            <div className="c71">Contact Number</div>
            <input
              className="c72"
              name="contact"
              value={values.contact}
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              placeholder="Enter here..."
            />
            {errors.contact && touched.contact ? (
              <div className="contact-error">{errors.contact}</div>
            ) : null}
          </div>

          <div className="c7">
            <div className="c71">Message</div>
            <input
              className="c72"
              name="text"
              value={values.text}
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              placeholder="Enter here..."
            />
            {errors.text && touched.text ? (
              <div className="contact-error">{errors.text}</div>
            ) : null}
          </div>

          <div className="c1_1">
            <button className="c7_btn" onClick={handleSubmit}>
              Send
            </button>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default Career
