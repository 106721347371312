import bnV from '../images/video/bnVider.mp4'

function uploadfile() {
  return (
    <>
        <video width="320" height="240" controls autoPlay muted loop>
          <source src={bnV} type="video/mp4" />
        </video>
    </>
  );
}

export default uploadfile;
