import logo from '../images/logo/MagnumLogo.png';
import menu from '../images/icons/menu.svg';
import close from '../images/icons/close.svg';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

// import Header from '../components/Header';
// import ban1 from '../images/banner/banner1.png';
// import img1 from '../images/img/img1.png';
// import comp2Img from '../images/img/comp2.png';
import Footer from '../components/Footer';

import im18 from '../images/img/18.png';
import im19 from '../images/img/19.png';
import im20 from '../images/img/20.png';

import ShuffleIcon from '@mui/icons-material/Shuffle';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import ScienceIcon from '@mui/icons-material/Science';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import BoltIcon from '@mui/icons-material/Bolt';
import ReduceCapacityIcon from '@mui/icons-material/ReduceCapacity';


function OAM() {

    const navigate = useNavigate()
  const [menuBtn, setMenuBtn] = useState(false);
  const [menu1, setMenu1] = useState(false);
  const [menu2, setMenu2] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (

    <>
    
    <div className='head_main desktop'>
          <div className='head_logo'><img src={logo} alt="logo" /></div>
          <div className='head_main_links'>
              <div onClick={() => navigate("/")} className='head_main_links_div '>Home</div>
              <div className='head_main_links_div' onMouseOver={() => setMenu1(true)} onMouseLeave={() => setMenu1(false)}>About Us
              {menu1 && (
                  <div className='head_main_links_cont' onMouseOver={() => setMenu1(true)} onMouseLeave={() => setMenu1(false)}>
                      <div onClick={() => navigate("/CompanyProfile")} className='head_main_links_cont_link'>Company Profile</div>
                      <div onClick={() => navigate("/JourneyAndGrowth")} className='head_main_links_cont_link'>Magnum Journey & Growth</div>
                      <div onClick={() => navigate("/Teams")} className='head_main_links_cont_link'>Magnum Team</div>
                  </div>
              )}
              </div>
              <div className='head_main_links_div' onMouseOver={() => setMenu2(true)} onMouseLeave={() => setMenu2(false)}>Services
              {menu2 && (
                <div className='head_main_links_cont' onMouseOver={() => setMenu2(true)} onMouseLeave={() => setMenu2(false)}>
                  <div onClick={() => navigate("/BPO")} className='head_main_links_cont_link'>BPO</div>
                  <div onClick={() => navigate("/KPO")} className='head_main_links_cont_link'>KPO</div>
                  <div onClick={() => navigate("/OperationAndMain")} className='head_main_links_cont_link activeLinkHead'>Operation & Maintanance</div>
                  <div onClick={() => navigate("/Utility")} className='head_main_links_cont_link'>Utility Management</div>
                  <div onClick={() => navigate("/Security")} className='head_main_links_cont_link'>Security Serivces</div>
                  <div onClick={() => navigate("/ITES")} className='head_main_links_cont_link'>ITES</div>
                </div>
              )}
              </div>
              <div onClick={() => navigate("/Industry")} className='head_main_links_div'>Industries</div>
              <div onClick={() => navigate("/NewsAndEvents")} className='head_main_links_div'>News & Events</div>
              <div onClick={() => navigate("/Career")} className='head_main_links_div'>Career</div>
              <div onClick={() => navigate("/Contact")} className='head_main_links_div'>Contact Us</div>
          </div>
      </div>

      <div className='head_main mobile'>
          <div className='head_logo'><img src={logo} alt="logo" /></div>
          <div className='head_menu_btn' onClick={() => setMenuBtn(true)}><img src={menu} alt="menu" /></div>
          {menuBtn && (
              <>
              <div className='mobile_cont'><img src={close} alt='close' onClick={() => setMenuBtn(false)} /></div>
              <div className='head_mobile_link'>
                  <div onClick={() => navigate("/")} className='head_main_links_div activeLinkHead'>Home</div>
                  <div className='head_main_links_div'>About Us</div>
                  <div className='head_main_links_cont'>
                      <div onClick={() => navigate("/CompanyProfile")} className='head_main_links_cont_link'>Company Profile</div>
                      <div onClick={() => navigate("/JourneyAndGrowth")} className='head_main_links_cont_link'>Magnum Journey & Growth</div>
                      <div onClick={() => navigate("/Teams")} className='head_main_links_cont_link'>Magnum Team</div>
                  </div>
                  <div className='head_main_links_div'>Services</div>
                  <div className='head_main_links_cont'>
                      <div onClick={() => navigate("/BPO")} className='head_main_links_cont_link'>BPO</div>
                      <div onClick={() => navigate("/KPO")} className='head_main_links_cont_link'>KPO</div>
                      <div onClick={() => navigate("/OperationAndMain")} className='head_main_links_cont_link'>Operation & Maintanance</div>
                      <div onClick={() => navigate("/Utility")} className='head_main_links_cont_link'>Utility Management</div>
                      <div onClick={() => navigate("/Security")} className='head_main_links_cont_link'>Security Serivces</div>
                      <div onClick={() => navigate("/ITES")} className='head_main_links_cont_link'>ITES</div>
                  </div>
                  <div onClick={() => navigate("/Industry")} className='head_main_links_div'>Industries</div>
                  <div onClick={() => navigate("/NewsAndEvents")} className='head_main_links_div'>News & Events</div>
                  <div onClick={() => navigate("/Career")} className='head_main_links_div'>Career</div>
                  <div onClick={() => navigate("/Contact")} className='head_main_links_div'>Contact Us</div>
                  
                  
              </div>
              </>
          )}
      </div>

        <div className='banCont_2' data-aos="fade-up">
        <div className='banTitleCont_2'>
          <div className='banTitInner_2'>
          <div className='banTit1_2'>Empowering Performance, Reliable Maintenance</div>
          </div>
        </div>
        <img src={im18} className='banImg_2' alt="banner" />
      </div>
        
        {/* comp 8 */}
        <div className='c1_1' style={{paddingBottom: 0, marginTop: 20, marginBottom: 40}} data-aos="fade-up">
            <div className='c8'>O & M – TELECOM TOWERS</div>
            <div className='c22'>Magnum is committed for providing flexible, integrated, dependable, cost effective and responsive
solutions for the Operation and Maintenance requirements of telecom industry. We have a proven
track record of maintaining more than 4000 towers.</div>
        </div>

        {/* comp 6 */}
        <div className='c6' data-aos="fade-up">

            <div className='c61'>
                <div className='c611'><img className='c6111' src={im19} alt='as' /></div>
                <div className='c612'>
                    <div className='cp011'>Preventive Maintenance</div>
                    <div className='c32'>Preventive maintenance is aimed at reducing 
the risk of faults and at maintaining the 
predetermined tolerance limits. On the basis 
of experience and statistics preventive work 
is done.            </div>
                </div>
            </div>

            <div className='c61 desktop'>
                <div className='c612'>
                    <div className='cp011'>Corrective Maintenance</div>
                    <div className='c32'>Corrective maintenance consists of activities 
for correcting any faults which occur. Faults may 
be, for example detected by alarms, complaints 
from customer or from checks during preventive 
maintenance work.   </div>
                </div>
                <div className='c611'><img className='c6111' src={im20} alt='as' /></div>
            </div>
            <div className='c61 mobile'>
                <div className='c611'><img className='c6111' src={im20} alt='as' /></div>
                <div className='c612'>
                    <div className='cp011'>Corrective Maintenance</div>
                    <div className='c32'>Corrective maintenance consists of activities 
for correcting any faults which occur. Faults may 
be, for example detected by alarms, complaints 
from customer or from checks during preventive 
maintenance work.   </div>
                </div>
            </div>

        </div>

        {/* comp 3 */}
        <div className='c3' style={{marginBottom: 30}}>
            <div className='c31'>SCOPE OF WORK</div>
            <div className='c32'>We have a massive experience in O&M services for passive equipment at telecom sites including 
Towers, Shelters, DG Sets, Power Panels, Power Plants, Diesel Filling Services, Battery banks. 
These O&M services include Preventive Maintenance & Corrective Fault Management Services. 
The teams performing are equipped with necessary competence and tools to meet high uptime for.</div>
            <div className='c33'><span></span> First Line Maintenance Services of all electrical and electronic equipments available at client site</div>
            <div className='c33'><span></span> Alarm management</div>
            <div className='c33'><span></span> Fault rectification</div>
            <div className='c33'><span></span> Housekeeping & rectification of site</div>
            <div className='c33'><span></span> Upkeep & maintenance of civil & mechanical installations at sites</div>
            <div className='c33'><span></span> Coordination with OEMs and other agencies</div>
            <div className='c33'><span></span> EB rectification</div>
            <div className='c33'><span></span> Spare management</div>
            <div className='c33'><span></span> The service aims at further improving the network quality</div>
            <div className='c33'><span></span> Electrical and Electronic</div>
        </div>

        {/* core values */}
        <div className='c2' data-aos="fade-up">
            <div className='c21'>WHY MAGNUM'S O&M?</div>
            <div className='c22'>We place client at the core of everything we do.</div>
            <div className='c23'>

                <div className='c231_c'>
                    <div className='c231'><ShuffleIcon /></div>
                    <div className='c232'>Flexibility</div>
                    <div className='c233'>We can create tailor made 
projects for the quality and time 
you seek within your budget</div>
                </div>
                <div className='c231_c'>
                    <div className='c231'><IntegrationInstructionsIcon /></div>
                    <div className='c232'>Integration</div>
                    <div className='c233'>Aiming at completing multi
disciplinary tasks & providing 
value added services through 
our in-house expertise</div>
                </div>
                <div className='c231_c'>
                    <div className='c231'><ScienceIcon /></div>
                    <div className='c232'>Experience</div>
                    <div className='c233'>15 years of experience in O&M of Passive infra Telecom Cell Sites and Optical Fiber Cable networks</div>
                </div>

            </div>
            <div className='c23'>

                <div className='c231_c'>
                    <div className='c231'><ContactPageIcon /></div>
                    <div className='c232'>Professionalism</div>
                    <div className='c233'>Honed by continual improvement 
programs, our professional services are 
maintained at high standards for 
customer satisfaction</div>
                </div>
                <div className='c231_c'>
                    <div className='c231'><BoltIcon /></div>
                    <div className='c232'>Man Power</div>
                    <div className='c233'>Our teams with their unique 
blend of knowledge, enthusiasm
 and ambition have turned our
company the forerunner in the industry</div>
                </div>
                <div className='c231_c'>
                    <div className='c231'><ReduceCapacityIcon /></div>
                    <div className='c232'>Capability</div>
                    <div className='c233'>We execute our projects 
successfully in stipulated time frame 
as per the defined SLAs and 
excel customer expectation.</div>
                </div>

            </div>
        </div>

        <Footer />

        
    </>

  );
}

export default OAM;
