import React, {useEffect, useState} from 'react';

import logo from '../images/logo/MagnumLogo.png';
import menu from '../images/icons/menu.svg';
import close from '../images/icons/close.svg';

import { useNavigate } from 'react-router-dom';

import Footer from '../components/Footer';
import img1 from '../images/img/img1.png';
import im1 from '../images/img/1.png';
import im2 from '../images/img/2.png';
import im3 from '../images/img/3.png';

// import bn from '../images/img/pexels-mart-production-7709252-r.png';
import bnVideo from '../images/video/bnVider.mp4';

import ReactPlayer from 'react-player'

import i1 from '../images/why_magnum/1.png';
import i2 from '../images/why_magnum/2.png';
import i3 from '../images/why_magnum/3.png';
import i4 from '../images/why_magnum/4.png';
import i5 from '../images/why_magnum/5.png';
import i6 from '../images/why_magnum/6.png';
import i7 from '../images/why_magnum/7.png';
import i8 from '../images/why_magnum/8.png';

import { useSpring, animated } from 'react-spring';

function Number ({n}) {
  const {number} = useSpring({
    from: {number: 0},
    number: n,
    delay: 200,
    config: {mass: 1, tension: 20, friction: 10},
  });
  return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>
}

function Home() {

  const navigate = useNavigate()
  const [menuBtn, setMenuBtn] = useState(false);

  const [menu1, setMenu1] = useState(false);
  const [menu2, setMenu2] = useState(false);

  useEffect(() => {
    

    window.scrollTo(0, 0);
  }, [])

  // const handle = () => {

  //       fetch("http://localhost/api/text_script.php", {
  //         method: 'GET',
  //         redirect: 'follow'
  //         })
  //       .then(response => response.text())
  //       .then(result => {
  //           console.log(result)
  //       })
  //       .catch(error => console.log('error', error));
  // }

  return (

    <div className='main_container'>

      {/* desktop */}
      <div className='head_main desktop'>
          <div className='head_logo'><img src={logo} alt="logo" /></div>
          <div className='head_main_links'>
              <div onClick={() => navigate("/")} className='head_main_links_div activeLinkHead'>Home</div>
              <div className='head_main_links_div' onMouseOver={() => setMenu1(true)} onMouseLeave={() => setMenu1(false)}>About Us
              {menu1 && (
                  <div className='head_main_links_cont' onMouseOver={() => setMenu1(true)} onMouseLeave={() => setMenu1(false)}>
                      <div onClick={() => navigate("/CompanyProfile")} className='head_main_links_cont_link'>Company Profile</div>
                      <div onClick={() => navigate("/JourneyAndGrowth")} className='head_main_links_cont_link'>Magnum Journey & Growth</div>
                      <div onClick={() => navigate("/Teams")} className='head_main_links_cont_link'>Magnum Team</div>
                  </div>
              )}
              </div>
              <div className='head_main_links_div' onMouseOver={() => setMenu2(true)} onMouseLeave={() => setMenu2(false)}>Services
              {menu2 && (
                <div className='head_main_links_cont' onMouseOver={() => setMenu2(true)} onMouseLeave={() => setMenu2(false)}>
                  <div onClick={() => navigate("/BPO")} className='head_main_links_cont_link'>BPO</div>
                  <div onClick={() => navigate("/KPO")} className='head_main_links_cont_link'>KPO</div>
                  <div onClick={() => navigate("/OperationAndMain")} className='head_main_links_cont_link'>Operation & Maintanance</div>
                  <div onClick={() => navigate("/Utility")} className='head_main_links_cont_link'>Utility Management</div>
                  <div onClick={() => navigate("/Security")} className='head_main_links_cont_link'>Security Serivces</div>
                  <div onClick={() => navigate("/ITES")} className='head_main_links_cont_link'>ITES</div>
                </div>
              )}
              </div>
              <div onClick={() => navigate("/Industry")} className='head_main_links_div'>Industries</div>
              <div onClick={() => navigate("/NewsAndEvents")} className='head_main_links_div'>News & Events</div>
              <div onClick={() => navigate("/Career")} className='head_main_links_div'>Career</div>
              <div onClick={() => navigate("/Contact")} className='head_main_links_div'>Contact Us</div>
          </div>
      </div>

      <div className='head_main mobile'>
          <div className='head_logo'><img src={logo} alt="logo" /></div>
          <div className='head_menu_btn' onClick={() => setMenuBtn(true)}><img src={menu} alt="menu" /></div>
          {menuBtn && (
              <>
              <div className='mobile_cont'><img src={close} alt='close' onClick={() => setMenuBtn(false)} /></div>
              <div className='head_mobile_link'>
                  <div onClick={() => navigate("/")} className='head_main_links_div'>Home</div>
                  <div className='head_main_links_div'>About Us</div>
                  <div className='head_main_links_cont'>
                      <div onClick={() => navigate("/CompanyProfile")} className='head_main_links_cont_link'>Company Profile</div>
                      <div onClick={() => navigate("/JourneyAndGrowth")} className='head_main_links_cont_link'>Magnum Journey & Growth</div>
                      <div onClick={() => navigate("/Teams")} className='head_main_links_cont_link'>Magnum Team</div>
                  </div>
                  <div className='head_main_links_div'>Services</div>
                  <div className='head_main_links_cont'>
                      <div onClick={() => navigate("/BPO")} className='head_main_links_cont_link'>BPO</div>
                      <div onClick={() => navigate("/KPO")} className='head_main_links_cont_link'>KPO</div>
                      <div onClick={() => navigate("/OperationAndMain")} className='head_main_links_cont_link'>Operation & Maintanance</div>
                      <div onClick={() => navigate("/Utility")} className='head_main_links_cont_link'>Utility Management</div>
                      <div onClick={() => navigate("/Security")} className='head_main_links_cont_link'>Security Serivces</div>
                      <div onClick={() => navigate("/ITES")} className='head_main_links_cont_link'>ITES</div>
                  </div>
                  <div onClick={() => navigate("/Industry")} className='head_main_links_div'>Industries</div>
                  <div onClick={() => navigate("/NewsAndEvents")} className='head_main_links_div'>News & Events</div>
                  <div onClick={() => navigate("/Career")} className='head_main_links_div'>Career</div>
                  <div onClick={() => navigate("/Contact")} className='head_main_links_div'>Contact Us</div>
                  
                  
              </div>
              </>
          )}
      </div>

      {/* /banner */}
      {/* <div className='banCont' data-aos="fade-up">
        <img src={bn} className='banImg' alt="banner" />
        <div className='banTitleCont'>
          <div className='banTitInner'>
            <div className='banTit1'>Your <br/>Business <br />Our Priority</div>
            <div className='banTit2'>Outsourcing made easy</div>
            <div className='banTit3'>Inbound and Outbound <span>Phone Channel Marketing</span> that Drive Performance and an Exceptional <span></span></div>
          </div>
        </div>
      </div> */}

      {/* /banner */}
      {/* <div className='banCont_2_home' data-aos="fade-up">
        <div className='banTitleCont_2'>
          <div className='banTitInner_2'>
            <div className='banTit1'>Your <br/>Business <br />Our Priority</div>
            <div className='banTit2'>Outsourcing made easy</div>
            <div className='banTit3'>Inbound and Outbound <span>Phone Channel Marketing</span> that Drive Performance and an Exceptional <span></span></div>
          </div>
        </div>
        <img src={bn} className='banImg_2_home' alt="banner" />
      </div> */}
      <div className='banCont_2_hom_4'>
        <div className='banTitleCont_2_4'>
          
          <video width="100" height="240" autoPlay muted loop>
            <source src={bnVideo} type="video/mp4" />
          </video>

          <div className='banTitInner_2_4'>
            <div className='banTit1_4'>Your <br/>Business <br />Our Priority</div>
            <div className='banTit2_4'>Outsourcing made easy</div>
            <div className='banTit3_4'>Inbound and Outbound <span>Phone Channel Email Chat Marketing</span> that Drive Performance and an Exceptional <span>Customer Experience</span></div>
            <button className='banBtn1'
             onClick={() => navigate("/Contact")}
            //  onClick={handle}
            >Contact Us</button>
          </div>

        </div>
      </div>

      {/* comp 11 */}
      <div className='c_11_cont'>

        <div className='c_15'>

          <div className='c_11_item'>
            <div className='c_11_txt1'><div className='c_11_txt1_cont'><div className='c_11_txt1_1'><Number n={30} /></div><div className='c_11_txt1_2'>+</div></div></div>
            <div className='c_11_txt2'>Years of Group Experience</div>
          </div>

          <div className='c_11_item'>
            <div className='c_11_txt1'><div className='c_11_txt1_cont'><div className='c_11_txt1_1'><Number n={10} /></div><div className='c_11_txt1_2'>+</div></div></div>
            <div className='c_11_txt2'>years of BPO/KPO experience</div>
          </div>

          <div className='c_11_item'>
            <div className='c_11_txt1'>
              <div className='c_11_txt1_cont'>
                <div className='c_11_txt1_1'>
                  <Number n={2600} />
                </div>
                <div className='c_11_txt1_2'>+</div>
              </div>
            </div>
            <div className='c_11_txt2'>Staff</div>
          </div>

        </div>

        <div className='c_15'>

          <div className='c_11_item'>
            <div className='c_11_txt1'><div className='c_11_txt1_cont'><div className='c_11_txt1_1'><Number n={1700} /></div><div className='c_11_txt1_2'>+</div></div></div>
            <div className='c_11_txt2'>Seats</div>
          </div>

          <div className='c_11_item'>
            <div className='c_11_txt1'><Number n={10} /></div>
            <div className='c_11_txt2'>Delivery Centers</div>
          </div>

          <div className='c_11_item'>
            <div className='c_11_txt1'><div className='c_11_txt1_cont'><div className='c_11_txt1_1'><Number n={5} /></div><div className='c_11_txt1_2'>+</div></div></div>
            <div className='c_11_txt2'>Million Interactions/ month</div>
          </div>

        </div>

      </div>
      

      {/* Industry Experience */}
      <div className='c1_1' style={{marginBottom: 30}} data-aos="fade-up">
        <div className='c1_11'>We have established partnerships with <b style={{fontWeight: 900}}>over 50 clients across</b> multiple domains</div>
        <div className='c1_13'>

          <div className='c1_131' onClick={() => navigate("/Industry#telecom")}>
            <img src={img1}  className='c1_1311' alt="image" />
            <div className='c1_1312'>
              Telecom
            </div>
          </div>

          <div className='c1_131' onClick={() => navigate("/Industry#BFSI")}>
            <img src={im1}  className='c1_1311' alt="image" />
            <div className='c1_1312'>
              BFSI
            </div>
          </div>

          <div className='c1_131' onClick={() => navigate("/Industry#ecommerce")}>
            <img src={im2}  className='c1_1311' alt="image" />
            <div className='c1_1312'>
              E-Commerce
            </div>
          </div>

          <div className='c1_131' onClick={() => navigate("/Industry#egovernance")}>
            <img src={im3}  className='c1_1311' alt="image" />
            <div className='c1_1312'>
              E-Governance
            </div>
          </div>
        </div>
      </div>

      {/* component 15 */}
      <div className='c15 desktop'>
        <div className='c15_head'>Why Magnum</div>

        <div className='c15_item_cont'>
          <div className='c15_item c15_item1'>
            <div className='c15_item_icon'><img src={i1} alt='icon' /></div>
            <div className='c15_item_content'>Seasoned partner with more than 10+ years of experience in Customer Service</div>
          </div>

          <div className='c15_item c15_item2'>
            <div className='c15_item_icon'><img src={i2} alt='icon' /></div>
            <div className='c15_item_content'>Robust Quality & Effective Governance Framework</div>
          </div>
        </div>

        <div className='c15_item_cont'>
          <div className='c15_item c15_item2'>
            <div className='c15_item_icon'><img src={i3} alt='icon' /></div>
            <div className='c15_item_content'>Quick Turnaround Time For Decisions</div>
          </div>

          <div className='c15_item c15_item1'>
            <div className='c15_item_icon'><img src={i4} alt='icon' /></div>
            <div className='c15_item_content'>Quick Scalability</div>
          </div>
        </div>

        <div className='c15_item_cont'>
          <div className='c15_item c15_item1'>
            <div className='c15_item_icon'><img src={i5} alt='icon' /></div>
            <div className='c15_item_content'>Cost-effective Yet Exceptional Performance</div>
          </div>

          <div className='c15_item c15_item2'>
            <div className='c15_item_icon'><img src={i6} alt='icon' /></div>
            <div className='c15_item_content'>In-house Software Development Team</div>
          </div>
        </div>

        <div className='c15_item_cont'>
          <div className='c15_item c15_item2'>
            <div className='c15_item_icon'><img src={i7} alt='icon' /></div>
            <div className='c15_item_content'>In-depth Business Analysis</div>
          </div>

          <div className='c15_item c15_item1'>
            <div className='c15_item_icon'><img src={i8} alt='icon' /></div>
            <div className='c15_item_content'>Vast Pipeline of Manpower Data</div>
          </div>
        </div>

      </div>

      {/* component 15 */}
      <div className='c15 mobile'>
        <div className='c15_head'>Why Magnum</div>

        <div className='c15_item_cont'>
          <div className='c15_item c15_item1'>
            <div className='c15_item_icon'><img src={i1} alt='icon' /></div>
            <div className='c15_item_content'>Seasoned partner with more than 10+ years of experience in Customer Service</div>
          </div>

          <div className='c15_item c15_item2'>
            <div className='c15_item_icon'><img src={i2} alt='icon' /></div>
            <div className='c15_item_content'>Robust Quality & Effective Governance Framework</div>
          </div>
        </div>

        <div className='c15_item_cont'>
          <div className='c15_item c15_item1'>
            <div className='c15_item_icon'><img src={i3} alt='icon' /></div>
            <div className='c15_item_content'>Quick Turnaround Time For Decisions</div>
          </div>

          <div className='c15_item c15_item2'>
            <div className='c15_item_icon'><img src={i4} alt='icon' /></div>
            <div className='c15_item_content'>Quick Scalability</div>
          </div>
        </div>

        <div className='c15_item_cont'>
          <div className='c15_item c15_item1'>
            <div className='c15_item_icon'><img src={i5} alt='icon' /></div>
            <div className='c15_item_content'>Cost-effective Yet Exceptional Performance</div>
          </div>

          <div className='c15_item c15_item2'>
            <div className='c15_item_icon'><img src={i6} alt='icon' /></div>
            <div className='c15_item_content'>In-house Software Development Team</div>
          </div>
        </div>

        <div className='c15_item_cont'>
          <div className='c15_item c15_item1'>
            <div className='c15_item_icon'><img src={i7} alt='icon' /></div>
            <div className='c15_item_content'>In-depth Business Analysis</div>
          </div>

          <div className='c15_item c15_item2'>
            <div className='c15_item_icon'><img src={i8} alt='icon' /></div>
            <div className='c15_item_content'>Vast Pipeline of Manpower Data</div>
          </div>
        </div>

      </div>

      <Footer />
    
    </div>

  );
}

export default Home;
