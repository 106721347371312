import logo from '../images/logo/MagnumLogo.png';
import menu from '../images/icons/menu.svg';
import close from '../images/icons/close.svg';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Header from '../components/Header';
import ban1 from '../images/banner/banner1.png';
import img1 from '../images/img/img1.png';
import comp2Img from '../images/img/comp2.png';
import Footer from '../components/Footer';

import im15 from '../images/img/15.png';
import im16 from '../images/img/16.png';
import im17 from '../images/img/17.png';

function KPO() {

    const navigate = useNavigate()
  const [menuBtn, setMenuBtn] = useState(false);
  const [menu1, setMenu1] = useState(false);
  const [menu2, setMenu2] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (

    <>
    
    <div className='head_main desktop'>
          <div className='head_logo'><img src={logo} alt="logo" /></div>
          <div className='head_main_links'>
              <div onClick={() => navigate("/")} className='head_main_links_div '>Home</div>
              <div className='head_main_links_div' onMouseOver={() => setMenu1(true)} onMouseLeave={() => setMenu1(false)}>About Us
              {menu1 && (
                  <div className='head_main_links_cont' onMouseOver={() => setMenu1(true)} onMouseLeave={() => setMenu1(false)}>
                      <div onClick={() => navigate("/CompanyProfile")} className='head_main_links_cont_link'>Company Profile</div>
                      <div onClick={() => navigate("/JourneyAndGrowth")} className='head_main_links_cont_link'>Magnum Journey & Growth</div>
                      <div onClick={() => navigate("/Teams")} className='head_main_links_cont_link'>Magnum Team</div>
                  </div>
              )}
              </div>
              <div className='head_main_links_div' onMouseOver={() => setMenu2(true)} onMouseLeave={() => setMenu2(false)}>Services
              {menu2 && (
                <div className='head_main_links_cont' onMouseOver={() => setMenu2(true)} onMouseLeave={() => setMenu2(false)}>
                  <div onClick={() => navigate("/BPO")} className='head_main_links_cont_link'>BPO</div>
                  <div onClick={() => navigate("/KPO")} className='head_main_links_cont_link activeLinkHead'>KPO</div>
                  <div onClick={() => navigate("/OperationAndMain")} className='head_main_links_cont_link'>Operation & Maintanance</div>
                  <div onClick={() => navigate("/Utility")} className='head_main_links_cont_link'>Utility Management</div>
                  <div onClick={() => navigate("/Security")} className='head_main_links_cont_link'>Security Serivces</div>
                  <div onClick={() => navigate("/ITES")} className='head_main_links_cont_link'>ITES</div>
                </div>
              )}
              </div>
              <div onClick={() => navigate("/Industry")} className='head_main_links_div'>Industries</div>
              <div onClick={() => navigate("/NewsAndEvents")} className='head_main_links_div'>News & Events</div>
              <div onClick={() => navigate("/Career")} className='head_main_links_div'>Career</div>
              <div onClick={() => navigate("/Contact")} className='head_main_links_div'>Contact Us</div>
          </div>
      </div>

      <div className='head_main mobile'>
          <div className='head_logo'><img src={logo} alt="logo" /></div>
          <div className='head_menu_btn' onClick={() => setMenuBtn(true)}><img src={menu} alt="menu" /></div>
          {menuBtn && (
              <>
              <div className='mobile_cont'><img src={close} alt='close' onClick={() => setMenuBtn(false)} /></div>
              <div className='head_mobile_link'>
                  <div onClick={() => navigate("/")} className='head_main_links_div activeLinkHead'>Home</div>
                  <div className='head_main_links_div'>About Us</div>
                  <div className='head_main_links_cont'>
                      <div onClick={() => navigate("/CompanyProfile")} className='head_main_links_cont_link'>Company Profile</div>
                      <div onClick={() => navigate("/JourneyAndGrowth")} className='head_main_links_cont_link'>Magnum Journey & Growth</div>
                      <div onClick={() => navigate("/Teams")} className='head_main_links_cont_link'>Magnum Team</div>
                  </div>
                  <div className='head_main_links_div'>Services</div>
                  <div className='head_main_links_cont'>
                      <div onClick={() => navigate("/BPO")} className='head_main_links_cont_link'>BPO</div>
                      <div onClick={() => navigate("/KPO")} className='head_main_links_cont_link'>KPO</div>
                      <div onClick={() => navigate("/OperationAndMain")} className='head_main_links_cont_link'>Operation & Maintanance</div>
                      <div onClick={() => navigate("/Utility")} className='head_main_links_cont_link'>Utility Management</div>
                      <div onClick={() => navigate("/Security")} className='head_main_links_cont_link'>Security Serivces</div>
                      <div onClick={() => navigate("/ITES")} className='head_main_links_cont_link'>ITES</div>
                  </div>
                  <div onClick={() => navigate("/Industry")} className='head_main_links_div'>Industries</div>
                  <div onClick={() => navigate("/NewsAndEvents")} className='head_main_links_div'>News & Events</div>
                  <div onClick={() => navigate("/Career")} className='head_main_links_div'>Career</div>
                  <div onClick={() => navigate("/Contact")} className='head_main_links_div'>Contact Us</div>
                  
                  
              </div>
              </>
          )}
      </div>

        <div className='banCont_2' data-aos="fade-up">
        <div className='banTitleCont_2'>
          <div className='banTitInner_2'>
          <div className='banTit1_2'>Transforming Challenges into opportunities: KPO Excellence</div>
          </div>
        </div>
        <img src={im15} className='banImg_2' alt="banner" />
      </div>

        
        {/* comp 8 */}
        <div className='c1_1' style={{paddingBottom: 0, marginTop: 20}} data-aos="fade-up">
            <div className='c8'>Knowledge Process Outsourcing</div>
            <div className='c22'>The key feature of KPO at Magnum Group is to facilitate the client organizations 
                to increase their revenues and to enable success. Through continuous research 
                it provides a platform to stretch the existing professional and technical capabilities further.
            </div>
        </div>

        {/* comp 4 */}
        <div className='c1_1' data-aos="fade-up">
            <div className='cp_0'>

                <div className='cp0 desktop'>
                    <div className='cp01'>
                        <div className='c4'>Need for KPO</div>
                        <div className='cp012'>KPO is needed to deal with the business 
challenges that gets created due to complexities 
involved in the core processes and increasing 
billing cost. As a part of KPO activity, skilled 
resources are essentially required to analyse 
and develop strategies to support businesses reach 
its goal. Business Process Outsourcing (BPO) 
supports the organization to create structured 
processes and enhance the business line through 
cost arbitrage. KPO demands a more focussed 
skills base. It controls and influences intellectual 
property and capacity than just the costs. In a 
broad sense, KPO involves components of Business 
Process Outsourcing, Research Process Outsourcing 
and Analyses Process Outsourcing. It requires 
advanced level analyses skills and business 
domain expertise than just the process expertise.
                        </div>
                    </div>
                    <div className='cp01'><img className='cp021' src={im16} alt='as' /></div>
                </div>

                <div className='cp0 mobile'>
                    <div className='cp01'><img className='cp021' src={im16} alt='as' /></div>
                    <div className='cp01'>
                        <div className='c4'>Need for KPO</div>
                        <div className='cp012'>KPO is needed to deal with the business 
challenges that gets created due to complexities 
involved in the core processes and increasing 
billing cost. As a part of KPO activity, skilled 
resources are essentially required to analyse 
and develop strategies to support businesses reach 
its goal. Business Process Outsourcing (BPO) 
supports the organization to create structured 
processes and enhance the business line through 
cost arbitrage. KPO demands a more focussed 
skills base. It controls and influences intellectual 
property and capacity than just the costs. In a 
broad sense, KPO involves components of Business 
Process Outsourcing, Research Process Outsourcing 
and Analyses Process Outsourcing. It requires 
advanced level analyses skills and business 
domain expertise than just the process expertise.
                        </div>
                    </div>
                </div>

            </div>
        </div>

        {/* comp 5 */}
        <div className='c1_1' style={{marginBottom: 80}}>
            <div className='cp_0'>
                <div className='cp0'>
                    <div className='cp01'><img className='cp021' src={im17} alt='as' /></div>
                    <div className='cp01'>
                        <div className='c4'>KPO Service @ MAGNUM</div>
                        <div className='c32'>At Magnum Group our KPO team is 
committed to providing analytical 
knowledge solutions to attend to complex 
business challenges.    </div>
                        <div className='c33'><span></span> Industry & Market Research</div>
                        <div className='c33'><span></span> Business & Technical Analysis</div>
                        <div className='c33'><span></span> Customer Surveys & Analysis</div>
                        <div className='c33'><span></span> Data Analytics</div>
                        <div className='c33'><span></span> Legal Research</div>
                        <div className='c33'><span></span> Intellectual Property Research</div>
                        <div className='c33'><span></span> Finance & Accounting Advisory Services</div>
                        <div className='c33'><span></span> Educational & Training Services</div>
                        <div className='c33'><span></span> Report Writing</div>
                        <div className='c33'><span></span> Data Management</div>
                    </div>
                </div>
            </div>
        </div>

        <Footer />

        
    </>

  );
}

export default KPO;
