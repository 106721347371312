import logo from '../images/logo/MagnumLogo.png'
import menu from '../images/icons/menu.svg'
import close from '../images/icons/close.svg'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useRef, useState } from 'react'
import Avatar from '../images/icons/avatar.svg'
import Footer from '../components/Footer'
import im41 from '../images/img/41.png'
import im42 from '../images/img/42.png'
import { careenFormSchema } from '../schema'
import { useFormik } from 'formik'

function Career() {
  const navigate = useNavigate()
  const [menuBtn, setMenuBtn] = useState(false)
  const [menu1, setMenu1] = useState(false)
  const [menu2, setMenu2] = useState(false)

  const applyBtnRef = useRef(null)
  const applyHandle = () => {
    applyBtnRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [res, setRes] = useState(null)

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    resetForm,
  } = useFormik({
    initialValues: {
      f_name: '',
      l_name: '',
      email: '',
      contact: '',
      dob: '',
      work_exp: '',
      area_of_exp: '',
    },
    validationSchema: careenFormSchema,
    onSubmit: (values) => {
      handleAPI(values)
      console.log(values)
    },
  })

  const handleAPI = (values) => {
    if (res === undefined) {
      alert('Please upload your resume!!!')
      return
    }

    var formdata = new FormData()
    formdata.append('first_name', values.f_name)
    formdata.append('last_name', values.l_name)
    formdata.append('date_of_birth', values.dob)
    formdata.append('email', values.email)
    formdata.append('contact_number', values.contact)
    formdata.append('work_in_exp', values.work_exp)
    formdata.append('area_of_expertise', values.area_of_exp)
    formdata.append('submit', 'true')
    formdata.append('resume', res.target.files[0], res.target.files[0].name)

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    }

    fetch('https://magnumgroup.co.in/career_contact.php', requestOptions)
      .then((response) => response.text())
      .then(() => {
        // console.log(result)
        alert('Our team will reach you out.')
        resetForm()
      })
      .catch((error) => console.log('error', error))
  }

  return (
    <>
      <div className="head_main desktop">
        <div className="head_logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="head_main_links">
          <div onClick={() => navigate('/')} className="head_main_links_div ">
            Home
          </div>
          <div
            className="head_main_links_div"
            onMouseOver={() => setMenu1(true)}
            onMouseLeave={() => setMenu1(false)}
          >
            About Us
            {menu1 && (
              <div
                className="head_main_links_cont"
                onMouseOver={() => setMenu1(true)}
                onMouseLeave={() => setMenu1(false)}
              >
                <div
                  onClick={() => navigate('/CompanyProfile')}
                  className="head_main_links_cont_link"
                >
                  Company Profile
                </div>
                <div
                  onClick={() => navigate('/JourneyAndGrowth')}
                  className="head_main_links_cont_link"
                >
                  Magnum Journey & Growth
                </div>
                <div
                  onClick={() => navigate('/Teams')}
                  className="head_main_links_cont_link"
                >
                  Magnum Team
                </div>
              </div>
            )}
          </div>
          <div
            className="head_main_links_div"
            onMouseOver={() => setMenu2(true)}
            onMouseLeave={() => setMenu2(false)}
          >
            Services
            {menu2 && (
              <div
                className="head_main_links_cont"
                onMouseOver={() => setMenu2(true)}
                onMouseLeave={() => setMenu2(false)}
              >
                <div
                  onClick={() => navigate('/BPO')}
                  className="head_main_links_cont_link"
                >
                  BPO
                </div>
                <div
                  onClick={() => navigate('/KPO')}
                  className="head_main_links_cont_link"
                >
                  KPO
                </div>
                <div
                  onClick={() => navigate('/OperationAndMain')}
                  className="head_main_links_cont_link"
                >
                  Operation & Maintanance
                </div>
                <div
                  onClick={() => navigate('/Utility')}
                  className="head_main_links_cont_link"
                >
                  Utility Management
                </div>
                <div
                  onClick={() => navigate('/Security')}
                  className="head_main_links_cont_link"
                >
                  Security Serivces
                </div>
                <div
                  onClick={() => navigate('/ITES')}
                  className="head_main_links_cont_link"
                >
                  ITES
                </div>
              </div>
            )}
          </div>
          <div
            onClick={() => navigate('/Industry')}
            className="head_main_links_div"
          >
            Industries
          </div>
          <div
            onClick={() => navigate('/NewsAndEvents')}
            className="head_main_links_div"
          >
            News & Events
          </div>
          <div
            onClick={() => navigate('/Career')}
            className="head_main_links_div activeLinkHead"
          >
            Career
          </div>
          <div
            onClick={() => navigate('/Contact')}
            className="head_main_links_div"
          >
            Contact Us
          </div>
        </div>
      </div>

      {/* <Component /> */}

      <div className="head_main mobile">
        <div className="head_logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="head_menu_btn" onClick={() => setMenuBtn(true)}>
          <img src={menu} alt="menu" />
        </div>
        {menuBtn && (
          <>
            <div className="mobile_cont">
              <img src={close} alt="close" onClick={() => setMenuBtn(false)} />
            </div>
            <div className="head_mobile_link">
              <div
                onClick={() => navigate('/')}
                className="head_main_links_div activeLinkHead"
              >
                Home
              </div>
              <div className="head_main_links_div">About Us</div>
              <div className="head_main_links_cont">
                <div
                  onClick={() => navigate('/CompanyProfile')}
                  className="head_main_links_cont_link"
                >
                  Company Profile
                </div>
                <div
                  onClick={() => navigate('/JourneyAndGrowth')}
                  className="head_main_links_cont_link"
                >
                  Magnum Journey & Growth
                </div>
                <div
                  onClick={() => navigate('/Teams')}
                  className="head_main_links_cont_link"
                >
                  Magnum Team
                </div>
              </div>
              <div className="head_main_links_div">Services</div>
              <div className="head_main_links_cont">
                <div
                  onClick={() => navigate('/BPO')}
                  className="head_main_links_cont_link"
                >
                  BPO
                </div>
                <div
                  onClick={() => navigate('/KPO')}
                  className="head_main_links_cont_link"
                >
                  KPO
                </div>
                <div
                  onClick={() => navigate('/OperationAndMain')}
                  className="head_main_links_cont_link"
                >
                  Operation & Maintanance
                </div>
                <div
                  onClick={() => navigate('/Utility')}
                  className="head_main_links_cont_link"
                >
                  Utility Management
                </div>
                <div
                  onClick={() => navigate('/Security')}
                  className="head_main_links_cont_link"
                >
                  Security Serivces
                </div>
                <div
                  onClick={() => navigate('/ITES')}
                  className="head_main_links_cont_link"
                >
                  ITES
                </div>
              </div>
              <div
                onClick={() => navigate('/Industry')}
                className="head_main_links_div"
              >
                Industries
              </div>
              <div
                onClick={() => navigate('/NewsAndEvents')}
                className="head_main_links_div"
              >
                News & Events
              </div>
              <div
                onClick={() => navigate('/Career')}
                className="head_main_links_div"
              >
                Career
              </div>
              <div
                onClick={() => navigate('/Contact')}
                className="head_main_links_div"
              >
                Contact Us
              </div>
            </div>
          </>
        )}
      </div>

      {/* /banner */}
      <div className="banCont_2" data-aos="fade-up">
        <div className="banTitleCont_2">
          <div className="banTitInner_2">
            <div className="banTit1_2">
              Join Our Team, Unleash Your Inner Champion
            </div>
          </div>
        </div>
        <img src={im41} className="banImg_2" alt="banner" />
      </div>

      {/* comp 4 */}
      <div className="c1_1" data-aos="fade-up">
        <div className="cp_0">
          <div className="cp0 desktop">
            <div className="cp01">
              <div className="c4">Few Reasons to Join Magnum Group</div>
              <div className="c33">
                <span></span> You can avail the opportunity to get trained by
                extremely qualified team.
              </div>
              <div className="c33">
                <span></span> You will get to think like an entrepreneur.
              </div>
              <div className="c33">
                <span></span> You will get to contribute in others life.
              </div>
              <div className="c33">
                <span></span> You will rarely wait for Sunday.
              </div>
              <div className="c33">
                <span></span> You will get to own up and create future with your
                own organisation.
              </div>
              <div className="c33">
                <span></span> You will get to create more efficiency and fun at
                work.
              </div>
              <div className="c33">
                <span></span> You can accomplish more here.
              </div>
              <div className="c33">
                <span></span> Learn to create a balance between professional and
                personal life.
              </div>
            </div>
            <div className="cp01">
              <img className="cp021" src={im42} alt="as" />
            </div>
          </div>

          <div className="cp0 mobile" data-aos="fade-up">
            <div className="cp01">
              <img className="cp021" src={im42} alt="as" />
            </div>
            <div className="cp01">
              <div className="c4">Few Reasons to Join Magnum Group</div>
              <div className="c33">
                <span></span> You can avail the opportunity to get trained by
                extremely qualified team.
              </div>
              <div className="c33">
                <span></span> You will get to think like an entrepreneur.
              </div>
              <div className="c33">
                <span></span> You will get to contribute in others life.
              </div>
              <div className="c33">
                <span></span> You will rarely wait for Sunday.
              </div>
              <div className="c33">
                <span></span> You will get to own up and create future with your
                own organisation.
              </div>
              <div className="c33">
                <span></span> You will get to create more efficiency and fun at
                work.
              </div>
              <div className="c33">
                <span></span> You can accomplish more here.
              </div>
              <div className="c33">
                <span></span> Learn to create a balance between professional and
                personal life.
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* comp 12 */}
      <div className="c_12" data-aos="fade-up">
        <div className="c8">Job Opening's</div>

        <div className="c_12_cont">
          <div className="c_12_item">
            <div className="c_12_1">
              <img className="c_12_11" src={Avatar} alt="avatar" />
              <div className="c_12_12">BHOPAL (INDIA)</div>
            </div>

            <div className="c_12_2">
              Customer Service Executive (Full Time Job)
            </div>
            <div className="c_12_3">
              <b>Salary</b> - Best in Industry
            </div>

            <ul className="c_12_4">
              <li>12th/Graduations/Under-graduates.</li>
              <li>Good Communication Skills.</li>
              <li>Freshers and Experienced Both can apply.</li>
            </ul>

            <div className="c_12_5_cont" ref={applyBtnRef}>
              <button className="c_12_5" onClick={applyHandle}>
                Apply Now
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* comp 8 */}
      <div
        className="c1_1"
        style={{ paddingBottom: 0, marginTop: 20 }}
        data-aos="fade-up"
      >
        <div className="c8">Join The Team</div>
        <div className="c22">
          Join our strong workforce to work for the best and diverse teams, to
          enhance your creativity, your leadership skills and to broaden your
          horizon through exposure to a wide range of profiles in multiple
          departments.
        </div>
      </div>

      {/* comp 7 */}
      <div className="c1_1" style={{ marginTop: -130 }} data-aos="fade-up">
        <div className="c7_cont">
          <div className="c7">
            <div className="c71">First Name</div>
            <input
              className="c72"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.f_name}
              name="f_name"
              type="text"
              placeholder="Enter here..."
            />
            {errors.f_name && touched.f_name ? (
              <div className="contact-error">{errors.f_name}</div>
            ) : null}
          </div>

          <div className="c7">
            <div className="c71">Last Name</div>
            <input
              className="c72"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.l_name}
              name="l_name"
              type="text"
              placeholder="Enter here..."
            />
            {errors.l_name && touched.l_name ? (
              <div className="contact-error">{errors.l_name}</div>
            ) : null}
          </div>

          <div className="c7">
            <div className="c71">Date of Birth</div>
            <input
              className="c72"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.dob}
              name="dob"
              type="date"
              placeholder="Enter here..."
            />
            {errors.dob && touched.dob ? (
              <div className="contact-error">{errors.dob}</div>
            ) : null}
          </div>

          <div className="c7">
            <div className="c71">Email Address</div>
            <input
              className="c72"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              name="email"
              type="email"
              placeholder="Enter here..."
            />
            {errors.email && touched.email ? (
              <div className="contact-error">{errors.email}</div>
            ) : null}
          </div>

          <div className="c7">
            <div className="c71">Contact Number</div>
            <input
              className="c72"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.contact}
              name="contact"
              type="number"
              placeholder="Enter here..."
            />
            {errors.contact && touched.contact ? (
              <div className="contact-error">{errors.contact}</div>
            ) : null}
          </div>

          <div className="c7">
            <div className="c71">Work Experience in Years</div>
            <input
              className="c72"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.work_exp}
              name="work_exp"
              type="number"
              placeholder="Enter here..."
            />
            {errors.work_exp && touched.work_exp ? (
              <div className="contact-error">{errors.work_exp}</div>
            ) : null}
          </div>

          <div className="c7">
            <div className="c71">Area of Expertise</div>
            <input
              className="c72"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.area_of_exp}
              name="area_of_exp"
              type="text"
              placeholder="Enter here..."
            />
            {errors.area_of_exp && touched.area_of_exp ? (
              <div className="contact-error">{errors.area_of_exp}</div>
            ) : null}
          </div>

          <div className="c7">
            <div className="c71">Upload Your resume</div>
            <input
              className="c72"
              onChange={(e) => {
                setRes(e)
              }}
              name="resume"
              type="file"
              placeholder="Enter here..."
            />
          </div>

          <div className="c1_1">
            <button className="c7_btn" onClick={handleSubmit}>
              Send
            </button>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default Career
