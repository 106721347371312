import logo from '../images/logo/MagnumLogo.png';
import menu from '../images/icons/menu.svg';
import close from '../images/icons/close.svg';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Footer from '../components/Footer';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Groups2Icon from '@mui/icons-material/Groups2';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';

import im9 from '../images/img/9.png';
import im10 from '../images/img/10.png';

function CompanyProfile() {

    const navigate = useNavigate()
  const [menuBtn, setMenuBtn] = useState(false);
  const [menu1, setMenu1] = useState(false);
  const [menu2, setMenu2] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (

    <>
    
    <div className='head_main desktop'>
          <div className='head_logo'><img src={logo} alt="logo" /></div>
          <div className='head_main_links'>
              <div onClick={() => navigate("/")} className='head_main_links_div '>Home</div>
              <div className='head_main_links_div' onMouseOver={() => setMenu1(true)} onMouseLeave={() => setMenu1(false)}>About Us
              {menu1 && (
                  <div className='head_main_links_cont' onMouseOver={() => setMenu1(true)} onMouseLeave={() => setMenu1(false)}>
                      <div onClick={() => navigate("/CompanyProfile")} className='head_main_links_cont_linkactiveLinkHead'>Company Profile</div>
                      <div onClick={() => navigate("/JourneyAndGrowth")} className='head_main_links_cont_link'>Magnum Journey & Growth</div>
                      <div onClick={() => navigate("/Teams")} className='head_main_links_cont_link'>Magnum Team</div>
                  </div>
              )}
              </div>
              <div className='head_main_links_div' onMouseOver={() => setMenu2(true)} onMouseLeave={() => setMenu2(false)}>Services
              {menu2 && (
                <div className='head_main_links_cont' onMouseOver={() => setMenu2(true)} onMouseLeave={() => setMenu2(false)}>
                  <div onClick={() => navigate("/BPO")} className='head_main_links_cont_link'>BPO</div>
                  <div onClick={() => navigate("/KPO")} className='head_main_links_cont_link'>KPO</div>
                  <div onClick={() => navigate("/OperationAndMain")} className='head_main_links_cont_link'>Operation & Maintanance</div>
                  <div onClick={() => navigate("/Utility")} className='head_main_links_cont_link'>Utility Management</div>
                  <div onClick={() => navigate("/Security")} className='head_main_links_cont_link'>Security Serivces</div>
                  <div onClick={() => navigate("/ITES")} className='head_main_links_cont_link'>ITES</div>
                </div>
              )}
              </div>
              <div onClick={() => navigate("/Industry")} className='head_main_links_div'>Industries</div>
              <div onClick={() => navigate("/NewsAndEvents")} className='head_main_links_div'>News & Events</div>
              <div onClick={() => navigate("/Career")} className='head_main_links_div'>Career</div>
              <div onClick={() => navigate("/Contact")} className='head_main_links_div'>Contact Us</div>
          </div>
      </div>

      <div className='head_main mobile'>
          <div className='head_logo'><img src={logo} alt="logo" /></div>
          <div className='head_menu_btn' onClick={() => setMenuBtn(true)}><img src={menu} alt="menu" /></div>
          {menuBtn && (
              <>
              <div className='mobile_cont'><img src={close} alt='close' onClick={() => setMenuBtn(false)} /></div>
              <div className='head_mobile_link'>
                  <div onClick={() => navigate("/")} className='head_main_links_div'>Home</div>
                  <div className='head_main_links_div'>About Us</div>
                  <div className='head_main_links_cont'>
                      <div onClick={() => navigate("/CompanyProfile")} className='head_main_links_cont_link'>Company Profile</div>
                      <div onClick={() => navigate("/JourneyAndGrowth")} className='head_main_links_cont_link'>Magnum Journey & Growth</div>
                      <div onClick={() => navigate("/Teams")} className='head_main_links_cont_link'>Magnum Team</div>
                  </div>
                  <div className='head_main_links_div'>Services</div>
                  <div className='head_main_links_cont'>
                      <div onClick={() => navigate("/BPO")} className='head_main_links_cont_link'>BPO</div>
                      <div onClick={() => navigate("/KPO")} className='head_main_links_cont_link'>KPO</div>
                      <div onClick={() => navigate("/OperationAndMain")} className='head_main_links_cont_link'>Operation & Maintanance</div>
                      <div onClick={() => navigate("/Utility")} className='head_main_links_cont_link'>Utility Management</div>
                      <div onClick={() => navigate("/Security")} className='head_main_links_cont_link'>Security Serivces</div>
                      <div onClick={() => navigate("/ITES")} className='head_main_links_cont_link'>ITES</div>
                  </div>
                  <div onClick={() => navigate("/Industry")} className='head_main_links_div'>Industries</div>
                  <div onClick={() => navigate("/NewsAndEvents")} className='head_main_links_div'>News & Events</div>
                  <div onClick={() => navigate("/Career")} className='head_main_links_div'>Career</div>
                  <div onClick={() => navigate("/Contact")} className='head_main_links_div'>Contact Us</div>
                  
                  
              </div>
              </>
          )}
      </div>

    <div className='banCont_2 ' data-aos="fade-up">
        <div className='banTitleCont_2'>
          <div className='banTitInner_2'>
            <div className='banTit1_2 martop40'>About US</div>
            <div className='banTit2_2'>Empowering Businesses, Redefining Outsourcing.</div>
          </div>
        </div>
        <img src={im9} className='banImg_2' alt="banner" />
      </div>

        {/* company profile */}
        <div className='c1_1' data-aos="fade-up">
            <div className='cp_0'>

                <div className='cp0 desktop'>
                    <div className='cp01'>
                        <div className='cp011'>Our Story</div>
                        <div className='cp012'>Magnum group is managed by a team of experienced 
professionals with extensive international expertise in 
their respective areas. Magnum Group is committed to 
providing complete customer satisfaction, ethical and 
transparent business practices, appropriate response 
to technological change and passion to get things 
done. Headquartered in Bhopal (MP) Magnum group 
commenced its operations in 1993.</div>
                    </div>
                    <div className='cp01'><img className='cp021' src={im10} alt='as' /></div>
                </div>

                <div className='cp0 mobile'>
                    
                    <div className='cp01'><img className='cp021' src={im10} alt='as' /></div>
                    <div className='cp01'>
                        <div className='cp011'>Our Story</div>
                        <div className='cp012'>LMagnum group is managed by a team of experienced 
professionals with extensive international expertise in 
their respective areas. Magnum Group is committed to 
providing complete customer satisfaction, ethical and 
transparent business practices, appropriate response 
to technological change and passion to get things 
done. Headquartered in Bhopal (MP) Magnum group 
commenced its operations in 1993.</div>
                    </div>
                </div>

                <div className='c_13'>We are also into safety, security & utility management services catering majorly all telecom giants & Multinational industrial units with a team of 400 security personnel placed at various organizations all over Madhya Pradesh. We also take the contract for providing trained manpower, casual/contract labour, equipment and allied items to fulfil the task of such contracts. We are pleased to introduce ourselves as foremost professional consultants and contractors for provision of safety, security, manpower management and administration to Industrial Units, Business Organizations that needs top class services.</div>
                <div className='c_13'>We have two organizations, which carry out the functions of consultants and contractors for security, manpower managemnt and environment. The parent company is Magnum Group, which is 30 years old. and the two subsidiaries of Magnum
Group are Magnum Super Distributors India Private Limited and Magnum Environment Management
Private Limited. and has several prestigious contracts offering our consultancy and services with
special stress on BPO operations call centers and environment management, provision of various
categories of labour and security personnel on contract basis.</div>

                {/* <div className='cp0'>
                    <div className='cp01 box1'>
                        <div className='cp011'>Our Vision</div>
                        <div className='cp012'>To be the premier and preferred provider of business 
processing outsourcing, knowledge process outsourcing, 
operations & maintenance, utility management, security 
services in our chosen market.</div>
                    </div>
                    <div className='cp01 box1'>
                        <div className='cp011'>Our Mision</div>
                        <div className='cp012'>To help our clients maximize their customer lifetime 
value and increase their competitive advantage by 
helping drive productivity and efficiency while 
delivering measurable results.</div>
                    </div>
                </div> */}

                <div className='c_14'>
                    <div className='c_14_1'>
                        <div className='cp011'>Our Vision</div>
                        <div className='cp012'>To be the premier and preferred provider of business 
processing outsourcing, knowledge process outsourcing, 
operations & maintenance, utility management, security 
services in our chosen market.</div>
                    </div>
                    <div className='c_14_1'>
                        <div className='cp011'>Our Mission</div>
                        <div className='cp012'>To help our clients maximize their customer lifetime 
value and increase their competitive advantage by 
helping drive productivity and efficiency while 
delivering measurable results.</div>
                    </div>
                </div>

            </div>
        </div>

        {/* core values */}
        <div className='c2' data-aos="fade-up">
            <div className='c21'>Core Values</div>
            <div className='c22'>We place client at the core of everything we do.</div>
            <div className='c23'>

                <div className='c231_c'>
                    <div className='c231'><AccountCircleIcon /></div>
                    <div className='c232'>Client First</div>
                    <div className='c233'>We place client at the core of everything we do.</div>
                </div>
                <div className='c231_c'>
                    <div className='c231'><IntegrationInstructionsIcon /></div>
                    <div className='c232'>Integrity</div>
                    <div className='c233'>Being ethical, honest and
committed in all actions.</div>
                </div>
                <div className='c231_c'>
                    <div className='c231'><SettingsAccessibilityIcon /></div>
                    <div className='c232'>Quick Decisions</div>
                    <div className='c233'>Quick and integrated decision
making process..    </div>
                </div>

            </div>
            <div className='c23'>

                <div className='c231_c'>
                    <div className='c231'><ThumbUpIcon /></div>
                    <div className='c232'>Respect</div>
                    <div className='c233'>Sensitive to individual differences and treat everyone with dignity.</div>
                </div>
                <div className='c231_c'>
                    <div className='c231'><Groups2Icon /></div>
                    <div className='c232'>Collaboration</div>
                    <div className='c233'>"One Magnum” spirit as the uppermost in everything we do.</div>
                </div>
                <div className='c231_c'>
                    <div className='c231'><ImportContactsIcon /></div>
                    <div className='c232'>Learning</div>
                    <div className='c233'>Learn from experiences, share knowledge and creating innovative solutions.</div>
                </div>

            </div>
        </div>

        {/* comp 3 */}
        <div className='c3'>
            <div className='c31'>Core Strength</div>
            <div className='c33'><span></span> Personal involvement/devotion in the Business.</div>
            <div className='c33'><span></span> Infrastructure & experience to train manpower according to the requirements of the job</div>
            <div className='c33'><span></span> Skilled and dedicated manpower</div>
            <div className='c33'><span></span> Strong family background, local standing</div>
            <div className='c33'><span></span> Financially sound to fulfill new project</div>
            <div className='c33'><span></span> Ready-made office with hi-tech equipments</div>
            <div className='c33'><span></span> Known in and out of the area of operations</div>
            <div className='c33'><span></span> Good personal relations</div>
        </div>

        <Footer />
        
    </>

  );
}

export default CompanyProfile;
