import logo from '../images/logo/MagnumLogo.png';
import menu from '../images/icons/menu.svg';
import close from '../images/icons/close.svg';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Header from '../components/Header';
import ban1 from '../images/banner/banner1.png';
import img1 from '../images/img/img1.png';
import comp2Img from '../images/img/comp2.png';
import Footer from '../components/Footer';

import im38 from '../images/img/38.png';
import im39 from '../images/img/39.png';
import im40 from '../images/img/40.png';

function News() {

    const navigate = useNavigate()
  const [menuBtn, setMenuBtn] = useState(false);
  const [menu1, setMenu1] = useState(false);
  const [menu2, setMenu2] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (

    <>
    
    <div className='head_main desktop'>
          <div className='head_logo'><img src={logo} alt="logo" /></div>
          <div className='head_main_links'>
              <div onClick={() => navigate("/")} className='head_main_links_div '>Home</div>
              <div className='head_main_links_div' onMouseOver={() => setMenu1(true)} onMouseLeave={() => setMenu1(false)}>About Us
              {menu1 && (
                  <div className='head_main_links_cont' onMouseOver={() => setMenu1(true)} onMouseLeave={() => setMenu1(false)}>
                      <div onClick={() => navigate("/CompanyProfile")} className='head_main_links_cont_link'>Company Profile</div>
                      <div onClick={() => navigate("/JourneyAndGrowth")} className='head_main_links_cont_link'>Magnum Journey & Growth</div>
                      <div onClick={() => navigate("/Teams")} className='head_main_links_cont_link'>Magnum Team</div>
                  </div>
              )}
              </div>
              <div className='head_main_links_div' onMouseOver={() => setMenu2(true)} onMouseLeave={() => setMenu2(false)}>Services
              {menu2 && (
                <div className='head_main_links_cont' onMouseOver={() => setMenu2(true)} onMouseLeave={() => setMenu2(false)}>
                  <div onClick={() => navigate("/BPO")} className='head_main_links_cont_link'>BPO</div>
                  <div onClick={() => navigate("/KPO")} className='head_main_links_cont_link'>KPO</div>
                  <div onClick={() => navigate("/OperationAndMain")} className='head_main_links_cont_link'>Operation & Maintanance</div>
                  <div onClick={() => navigate("/Utility")} className='head_main_links_cont_link'>Utility Management</div>
                  <div onClick={() => navigate("/Security")} className='head_main_links_cont_link'>Security Serivces</div>
                  <div onClick={() => navigate("/ITES")} className='head_main_links_cont_link'>ITES</div>
                </div>
              )}
              </div>
              <div onClick={() => navigate("/Industry")} className='head_main_links_div'>Industries</div>
              <div onClick={() => navigate("/NewsAndEvents")} className='head_main_links_div activeLinkHead'>News & Events</div>
              <div onClick={() => navigate("/Career")} className='head_main_links_div'>Career</div>
              <div onClick={() => navigate("/Contact")} className='head_main_links_div'>Contact Us</div>
          </div>
      </div>

      <div className='head_main mobile'>
          <div className='head_logo'><img src={logo} alt="logo" /></div>
          <div className='head_menu_btn' onClick={() => setMenuBtn(true)}><img src={menu} alt="menu" /></div>
          {menuBtn && (
              <>
              <div className='mobile_cont'><img src={close} alt='close' onClick={() => setMenuBtn(false)} /></div>
              <div className='head_mobile_link'>
                  <div onClick={() => navigate("/")} className='head_main_links_div activeLinkHead'>Home</div>
                  <div className='head_main_links_div'>About Us</div>
                  <div className='head_main_links_cont'>
                      <div onClick={() => navigate("/CompanyProfile")} className='head_main_links_cont_link'>Company Profile</div>
                      <div onClick={() => navigate("/JourneyAndGrowth")} className='head_main_links_cont_link'>Magnum Journey & Growth</div>
                      <div onClick={() => navigate("/Teams")} className='head_main_links_cont_link'>Magnum Team</div>
                  </div>
                  <div className='head_main_links_div'>Services</div>
                  <div className='head_main_links_cont'>
                      <div onClick={() => navigate("/BPO")} className='head_main_links_cont_link'>BPO</div>
                      <div onClick={() => navigate("/KPO")} className='head_main_links_cont_link'>KPO</div>
                      <div onClick={() => navigate("/OperationAndMain")} className='head_main_links_cont_link'>Operation & Maintanance</div>
                      <div onClick={() => navigate("/Utility")} className='head_main_links_cont_link'>Utility Management</div>
                      <div onClick={() => navigate("/Security")} className='head_main_links_cont_link'>Security Serivces</div>
                      <div onClick={() => navigate("/ITES")} className='head_main_links_cont_link'>ITES</div>
                  </div>
                  <div onClick={() => navigate("/Industry")} className='head_main_links_div'>Industries</div>
                  <div onClick={() => navigate("/NewsAndEvents")} className='head_main_links_div'>News & Events</div>
                  <div onClick={() => navigate("/Career")} className='head_main_links_div'>Career</div>
                  <div onClick={() => navigate("/Contact")} className='head_main_links_div'>Contact Us</div>
                  
                  
              </div>
              </>
          )}
      </div>


        <div className='banCont_2' data-aos="fade-up">
        <div className='banTitleCont_2'>
          <div className='banTitInner_2'>
          <div className='banTit1_2'>Dive into <br />the World<br/> of Magnum</div>
          </div>
        </div>
        <img src={im38} className='banImg_2' alt="banner" />
      </div>

        
        {/* comp 8 */}
        <div className='c1_1 c1_1_ext' style={{paddingBottom: 0, marginTop: 20}}  data-aos="fade-up">
            <div className='c8'>NEWS & EVENTS</div>
        </div>

        {/* comp 4 */}
        <div className='c1_1 c1_1_ext'>
            <div className='cp_0'>

                <div className='cp0 desktop' data-aos="fade-up">
                    <div className='cp01'>
                        <div className='c4'>5 Years Club</div>
                        <div className='cp012'>For the world is Magnum Group, but for its employees it has always been Magnum Family and a family that stays together through thick n’ thin, flourishes together. With that thought in mind Magnum employees tend to stay with their organization for an extended period of time. This January’22 we celebrated 5Year club to appreciate magnum family members who have been associated with Magnum for last 5 years and more.</div>
                    </div>
                    <div className='cp01'><img className='cp021' src={im39} alt='as' /></div>
                </div>

                <div className='cp0 mobile' data-aos="fade-up">
                    <div className='cp01'><img className='cp021' src={im39} alt='as' /></div>
                    <div className='cp01'>
                        <div className='c4'>5 Years Club</div>
                        <div className='cp012'>For the world is Magnum Group, but for its employees it has always been Magnum Family and a family that stays together through thick n’ thin, flourishes together. With that thought in mind Magnum employees tend to stay with their organization for an extended period of time. This January’22 we celebrated 5Year club to appreciate magnum family members who have been associated with Magnum for last 5 years and more.</div>
                    </div>
                </div>

            </div>
        </div>

        {/* comp 5 */}
        <div className='c1_1 c1_1_ext marbotm30' data-aos="fade-up">
            <div className='cp_0'>
                <div className='cp0'>
                    <div className='cp01'><img className='cp021' src={im40} alt='as' /></div>
                    <div className='cp01'>
                        <div className='c4'>Yearly RNR Celebration</div>
                        <div className='c32'>Hard work, Dedication and Consistency is what makes magnum employees best in the industry. As an organization we believe it’s our responsibility to reward and appreciate the employees who have been consistent best performers throughout the year. The sheer willpower and strategic approach towards one’s job drives our employees to deliver their best consistently.</div>
                    </div>
                </div>
            </div>
        </div>


        <Footer />

        
    </>

  );
}

export default News;
